<template>
  <v-container fluid ma-0 pa-0 fill-heigh>
    <v-card color="grey lighten-4" flat height="50px" tile>
      <v-app-bar color="#0097A7" dense dark fixed>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>Cambio24 - operaciones</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon :to="'/'">
          <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn dark v-bind="attrs" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="cerrarSesion">
              <v-list-item-title>Cerrar Sesión</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </v-card>

    <v-navigation-drawer
      disable-route-watcher
      v-model="drawer"
      :color="color"
      :right="right"
      :src="bg"
      fixed
      temporary
    >
      <v-list dense nav class="py-0">
        <v-list-item two-line :class="miniVariant && 'px-0'" class="pl-0">
          <v-list-item-avatar>
            <v-avatar color="red" width="25">
              <span class="white--text text-h5">{{
                cnombres.substring(0, 1).toUpperCase()
              }}</span>
            </v-avatar>
          </v-list-item-avatar>

          <v-list-item-content v-if="estado.logueado">
            <v-list-item-title>{{ cemail }}</v-list-item-title>
            <v-list-item-subtitle
              >{{ cnombres }} {{ capaterno }}
              {{ camaterno }}</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.ruta"
          @click="irRuta()"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-icon v-bind="attrs" v-on="on">
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </v-list-item-icon>
            </template>
            <span>{{ item.title }}</span>
          </v-tooltip>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Barmenu",
  data() {
    return {
      drawer: false,
      items: [],
      color: "white",
      colors: ["orange"],
      right: false,
      permanent: false,
      miniVariant: false,
      background: false,
      cnombres: "",
      capaterno: "",
      camaterno: "",
      cemail: "",
      idcliente: "",
      habil: "",
    };
  },
  computed: {
    bg() {
      return this.background
        ? "https://cdn.vuetifyjs.com/images/backgrounds/bg-2.jpg"
        : undefined;
    },
    ...mapState("cliente", ["clientes", "estado"]),
  },
  async created() {
    this.$root.$refs.menudatos = this;
    await this.asignardatos();
  },
  methods: {
    ...mapActions("cliente", ["logout"]),
    estadoBar() {
      this.miniVariant = !this.miniVariant;
      this.showapp = !this.showapp;
    },
    async cerrarSesion() {
      await this.logout();
      this.$router.push("/login");
    },
    async asignardatos() {
      //console.log(this.clientes);
      if (this.estado.logueado) {
        this.cemail = this.clientes.correo;
        this.cnombres = this.clientes.nombres;
        this.capaterno = this.clientes.apaterno;
        this.camaterno = this.clientes.amaterno;
        this.habil = this.clientes.habil;
      }

      this.asignarMenuCliente()
    },
    asignarMenuCliente() {
       this.items = []
      if (this.habil !== "OK") {
        this.items = [
          {
            title: "Nueva operación",
            icon: "mdi-plus-box",
            ruta: "/nueva-operacion",
            color: "blue",
          },
          {
            title: "Perfiles",
            icon: "mdi-account-supervisor-circle",
            ruta: "/perfil",
          },
          {
            title: "Operaciones",
            icon: "mdi-cash-multiple",
            ruta: "/operaciones",
            color: "blue",
          },
          { title: "Cuentas", icon: "mdi-account-cash", ruta: "/cuentas" },
          {
            title: "Cambiar contraseña",
            icon: "mdi-form-textbox-password",
            ruta: "/cambiar-clave",
          },
        ];
      } else {
        this.items = [
          {
            title: "Nueva Operacion en efectivo",
            icon: "mdi-plus-circle-multiple",
            ruta: "/nueva-operacion-efectivo",
            color: "teal",
          },
          {
            title: "Nueva operación",
            icon: "mdi-plus-box",
            ruta: "/nueva-operacion",
            color: "blue",
          },
          {
            title: "Perfiles",
            icon: "mdi-account-supervisor-circle",
            ruta: "/perfil",
          },
          {
            title: "Operaciones en efectivo",
            icon: "mdi-cash-multiple",
            ruta: "/operaciones-en-efectivo",
            color: "teal",
          },
          {
            title: "Operaciones",
            icon: "mdi-cash-multiple",
            ruta: "/operaciones",
            color: "blue",
          },
          { title: "Cuentas", icon: "mdi-account-cash", ruta: "/cuentas" },
          {
            title: "Cambiar contraseña",
            icon: "mdi-form-textbox-password",
            ruta: "/cambiar-clave",
          },
        ];
      }
     // console.log(this.habil)
      //console.log(this.items)
    },
    irRuta() {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "auto",
        });
      }, 100);
      this.drawer = false;
    },
  },
};
</script>