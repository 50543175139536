import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    cuentausuarios: [],
    cuentausuarioeditar: [],
}

const actions = {
    async listarCuentas({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/cuentausuario.php`, opciones)
            const contenido = await response.json()
            commit('obtenerCuentas', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarCuentaPrincipal({commit}, elemento) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/cuentausuario.php?principal=${elemento.principal}&banco=${elemento.banco}&moneda=${elemento.moneda}`, opciones)
            const contenido = await response.json()
            commit('obtenerCuentaPrincipal', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarCuentaBancoMoneda({commit}, elemento) {
        const opciones = {
            method: 'GET'
        }
        try {
            //console.log(`${direccion().url}/cuentausuario.php?banco=${elemento.banco}&moneda=${elemento.moneda}`)
            let response = await fetch(`${direccion().url}/cuentausuario.php?banco=${elemento.banco}&moneda=${elemento.moneda}`, opciones)
            const contenido = await response.json()
            commit('obtenerCuentaBancoMoneda', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    asignarCuentausuarioEditar({commit}, elementoEmpresa) {
        try {
            commit('asignarCuentausuario', elementoEmpresa)
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    obtenerCuentas(state, contenido) {
        state.all = {resultado : contenido}
        state.cuentausuarios = contenido
    },
    obtenerCuentaPrincipal(state, contenido) {
        state.all = {resultado : contenido}
        state.cuentausuarios = contenido
    },
    obtenerCuentaBancoMoneda(state, contenido) {
        state.all = {resultado : contenido}
        state.cuentausuarios = contenido
    },
    asignarCuentausuario(state, contenido) {
        state.cuentausuarioeditar = contenido
    },
    agregarCuentausuario(state, contenido) {
        state.all = {resultado : contenido}
    },
    editarCuentausuario(state, contenido) {
        state.cuentausuarios = contenido
    }, 
    eliminarCuentausuario(state, contenido) {
        state.all = {resultado: contenido}
    },
}

export const cuentausuario = {
    namespaced: true,
    state,
    actions,
    mutations
}