import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    empresacliente: [],
    empresas: [],
    empresaEditar: [],
    contactoempresas: [],
    resultadoEmpresa: {},
}

const actions = {
    async listarEmpresasPorCliente({commit}, idcliente) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/empresas.php?idcliente=${idcliente}`, opciones)
            const contenido = await response.json()
            commit('obtenerEmpresaCliente', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarContactoEmpresas({commit}, idcliente) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/empresas.php?accion=li001&idcliente=${idcliente}`, opciones)
            const contenido = await response.json()
            commit('obtenerContactoEmpresas', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async agregar({commit}, elementoAgregar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoAgregar)
        }
        try {
            console.log(JSON.stringify(elementoAgregar))
            let respuesta = await fetch(`${direccion().url}/empresas.php?accion=ag002`, opciones)
            const contenido = await respuesta.json()
            commit('agregarEmpresa', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editar({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            let respuesta = await fetch(`${direccion().url}/empresas.php?accion=ed001`, opciones)
            const contenido = await respuesta.json()
            commit('editarEmpresa', contenido);
        } catch (error) {
            console.error(error)
        }
    },
    asignarEmpresaEditar({commit}, elementoEmpresa) {
        try {
            commit('asignarEmpresa', elementoEmpresa)
        } catch (error) {
            console.error(error)
        }
    },
    async traerDatosEmpresa({commit}, id) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/empresas.php?accion=li002&id=${id}`, opciones)
            const contenido = await response.json()
            commit('obtenerDatosEmpresa', contenido)
        } catch (error) {
            console.error(error)
        }
    }
}

const mutations = {
    obtenerEmpresaCliente(state, contenido) {
        state.all = {resultado : contenido}
        state.empresacliente = contenido
    },
    obtenerContactoEmpresas(state, contenido) {
        state.all = {resultado : contenido}
        state.contactoempresas = contenido
    },
    agregarEmpresa(state, contenido) {
        state.all = {resultado : contenido}
        state.resultadoEmpresa = contenido
    },
    asignarEmpresa(state, contenido) {
        state.empresaEditar = contenido
    },
    editarEmpresa(state, contenido) {
        state.resultadoEmpresa = contenido
    },
    obtenerDatosEmpresa(state, contenido) {
        state.all = {resultado : contenido}
        state.empresaEditar = contenido
    },
}

export const empresa = {
    namespaced: true,
    state,
    actions,
    mutations
}