import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    cuentabancaria: [],
    cuentabancariacliente: [],
    cuentabancariacliente2: [],
    cuentabancariacliente3: [],
    cuentabancariaeditar: [],
    cuentaenvio: 2,
    cuentaid: 0,
    resultadoEditar: '',
    cuentabancariaPerfil: [],
}

const actions = {
    async listarCuentasPorClienteSoles({commit}, item) {
        const opciones = {
            method: 'GET'
        }
        try {
            //console.log(item)
            let response = await fetch(`${direccion().url}/cuentabancaria.php?accion=li001&idcliente=${item.id}&perfil=${item.tipo}`, opciones)
            const contenido = await response.json()
            commit('obtenerCuentaClienteSoles', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarCuentasPorClienteDolares({commit}, item) {
        const opciones = {
            method: 'GET'
        }
        try {
            //console.log(`${direccion().url}/cuentabancaria.php?accion=li002&idcliente=${item.id}&perfil=${item.tipo}`)
            let response = await fetch(`${direccion().url}/cuentabancaria.php?accion=li002&idcliente=${item.id}&perfil=${item.tipo}`, opciones)
            const contenido = await response.json()
            commit('obtenerCuentaClienteDolares', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarCuentasPorCliente({commit}, idcliente) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/cuentabancaria.php?accion=li003&idcliente=${idcliente}`, opciones)
            const contenido = await response.json()
            commit('obtenerCuentaCliente', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async agregar({commit}, elementoAgregar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoAgregar)
        }
        try {
            //console.log(JSON.stringify(elementoAgregar))
            let respuesta = await fetch(`${direccion().url}/cuentabancaria.php`, opciones)
            const contenido = await respuesta.json()
            commit('agregarCuentabancaria', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editar({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            //console.log(JSON.stringify(elementoEditar))
            let respuesta = await fetch(`${direccion().url}/cuentabancaria.php?accion=up001`, opciones)
            const contenido = await respuesta.json()
            commit('editarCuentabancaria', contenido);
        } catch (error) {
            console.error(error)
        }
    }, 
    async eliminar({commit}, id) {
        const opciones = {
            method: 'DELETE',
        }
        try {
            let respuesta = await fetch(`${direccion().url}/cuentabancaria.php?id=${id}`, opciones)
            const contenido = await respuesta.json()
            commit('eliminarCuentabancaria', contenido) 
        } catch (error) {
            console.error(error)
        }
    },
    asignarCuentabancariaEditar({commit}, elementoEmpresa) {
        try {
            commit('asignarCuentabancaria', elementoEmpresa)
        } catch (error) {
            console.error(error)
        }
    },
    asignarCuentabancariaAgregarId({commit}, idcliente) {
        try {
            commit('darCuentabancariaAgregarId', idcliente)
        } catch (error) {
            console.error(error)
        }
    },
    asignarCuentaenlista({commit}, elemento) {
        try {
            commit('asignarCuentaLista', elemento)
        } catch (error) {
            console.error(error)
        }
    },
    soloAsignarPerfil({commit}, perfil) {
        try {
            commit('soloDarPerfil', perfil)
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    obtenerCuentaClienteSoles(state, contenido) {
        state.all = {resultado : contenido}
        if(contenido[0].resultado == "vacio"){
            state.cuentabancariacliente = []
        } else {
            state.cuentabancariacliente = contenido
        }
    },
    obtenerCuentaClienteDolares(state, contenido) {
        state.all = {resultado : contenido}
        if(contenido[0].resultado == "vacio"){
            state.cuentabancariacliente2 = []
        } else {
            state.cuentabancariacliente2 = contenido
        }
    },
    obtenerCuentaCliente(state, contenido) {
        state.all = {resultado : contenido}
        state.cuentabancariacliente3 = contenido
    },
    agregarCuentabancaria(state, contenido) {
        state.all = {resultado : contenido}
    },
    asignarCuentabancaria(state, contenido) {
        state.cuentabancariaeditar = contenido
    },
    darCuentabancariaAgregarId(state, idcliente) {
        state.cuentaid = idcliente
    },
    asignarCuentaLista(state, contenido) {
        console.log(contenido)
        state.cuentaenvio = contenido
    },
    editarCuentabancaria(state, contenido) {
        state.resultadoEditar = contenido
    }, 
    eliminarCuentabancaria(state, contenido) {
        state.all = {resultado: contenido}
    },
    soloDarPerfil(state, contenido) {
        state.cuentabancariaPerfil[0] = contenido
    },
}

export const cuentabancaria = {
    namespaced: true,
    state,
    actions,
    mutations
}