<template>
  <div class="login">
    <v-container class="fill-height" fluid>
      
        <v-app-bar color="#0097A7" dense dark fixed>
          <div class="contenedor-head">
          <v-toolbar-title class="ml-10 mt-2"><a href="https://cambio24peru.com/"><img src="../assets/logo-cambio-24-whitebg.png" alt="" width="130" class="pl-5"></a></v-toolbar-title>
          <v-spacer></v-spacer>
          </div>
        </v-app-bar>
      
      <v-row class="mt-10 pt-5" justify="center">
         <v-col cols="12" sm="12" md="12" >
          <div class="d-flex align-center justify-center">
            <a href="https://cambio24peru.com/" class="mx-0 text-center"><span class="mdi mdi-arrow-left me-1"></span>Volver al inicio</a>
          </div>          
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <div class="text-center">Empiece a realizar sus cambios en linea</div>
        </v-col>
        <v-col cols="12" sm="12" class="text-center">
          <v-card class="elevation-5 ma-auto" max-width="400">
            <v-toolbar color="#0097A7" dark flat>
              <v-toolbar-title>Bienvenido a Cambio24</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <span>Origen</span>
              </v-tooltip>
            </v-toolbar>
            <v-form
              ref="form"
              v-model="validar"
              @submit.prevent="iniciarsesion"
              id="loginform"
              class="pt-6 pr-5"
            >
              <v-card-text>
                <v-text-field
                  label="Email"
                  v-model="email"
                  :rules="emailReglas"
                  prepend-icon="mdi-account"
                  type="text"
                  v-on:click="onChange"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-model="contrasena"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="contrasenaReglas"
                  :type="show1 ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  name="input-10-1"
                  label="Contraseña"
                  counter
                  @click:append="show1 = !show1"
                  outlined
                ></v-text-field>
              </v-card-text>
              <v-card-actions class="pb-4 pr-6 pl-6">
                <v-spacer></v-spacer>
                <v-btn
                  type="submit"
                  form="loginform"
                  color="primary"
                  class="pt-2 pb-2"
                  block
                  >Iniciar</v-btn
                >
              </v-card-actions>
            </v-form>
            <v-alert v-if="alerta.message" :type="alerta.type">
              {{ alerta.message }}
            </v-alert>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="12" class="pt-5">
          <div class="text-center">
            <a href="https://registro.cambio24peru.com/reestablecer-clave">Olvide mi contraseña</a>
          </div>
        </v-col>

        <v-col cols="12" sm="6" md="3" class="pt-5 mb-10 text-center">
          <v-btn
            color="yellow"
            class="mt-5"
            href="https://registro.cambio24peru.com/"
            >Deseo crear nueva cuenta</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="pe-5">
        <v-col cols="12" class="text-right">
            <v-btn
                color="green accent-4"
                fixed 
                dark
                bottom
                right
                fab
                href="https://api.whatsapp.com/send?phone=51997327111"
                target="_blank"
                class="mb-3"
              >
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      show1: false,
      validar: true,
      email: "",
      emailReglas: [(v) => !!v || "Ingrese el E-mail"],
      contrasena: "",
      contrasenaReglas: [(v) => !!v || "Ingrese la contraseña"],
    };
  },
  computed: {
    ...mapState({ alerta: (state) => state.alerta }),
    ...mapState("cliente", ["estado", "clientes"]),
  },
  created() {
    console.log("aqui");
  },
  methods: {
    ...mapActions("cliente", ["login", "logout"], {
      clearAlert: "alert/clear",
    }),
    async iniciarsesion() {
      if (this.$refs.form.validate()) {
        const { email, contrasena } = this;
        if (email && contrasena) {
          await this.login({ email, contrasena });
          this.$root.$refs.menudatos.asignardatos();
        }
      }
    },
    onChange() {
      this.alerta.message = null;
    },
  },
  watch: {},
};
</script>
<style>
.contenedor-head{
  position: relative !important;
    max-width: 1200px !important;
}
</style>