<template>
  <div :class="altodinamico">
    <v-row class="pa-5">
      <v-col cols="12" md="5" sm="5" lg="5" xl="5">
        <div class="pa-4 pl-0 pt-1">
          <span
            class="rounded amber lighten-2 pa-2 text-body-1 font-weight-medium"
            >Cambiar la contraseña</span
          >
        </div>
        <v-card>
          <v-card-title> </v-card-title>
          <v-card-text>
            <v-form ref="formTipocambio" v-model="formulario.validar">
              <v-container>
                <v-row>
                  <v-col cols="12" class="pb-0 pt-0">
                    <v-text-field
                      v-model="elem.contrasena"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[required, min6]"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Contraseña"
                      counter
                      @click:append="show1 = !show1"
                      outlined
                    ></v-text-field>
                    <v-text-field
                      v-model="elem.contrasena2"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[required, min6, validarcontras]"
                      :type="show2 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Confirmar contraseña"
                      counter
                      @click:append="show2 = !show2"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :disabled="!formulario.validar"
              :loading="formulario.cargar"
              @click="cambiar"
              >Cambiar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog2" max-width="450">
        <v-card>
          <v-avatar :color="colormensaje">
            <v-icon dark>
              {{ iconomensaje }}
            </v-icon>
          </v-avatar>
          <v-card-title class="text-h5 justify-center headerClass">
            {{ titulomensaje }}
          </v-card-title>
          <v-card-text class="pt-4 text-body-1 font-weight-medium text-justify"
            >{{ textomensaje }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrar"> Ok </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      textomensaje: "",
      iconomensaje: "",
      titulomensaje: "",
      colormensaje: "",
      dialog2: false,
      mostrarmensaje: false,
      isDisabled: false,

      show1: false,
      show2: false,
      formulario: {
        validar: true,
        cargar: false,
      },
      elem: {
        contrasena: "",
        contrasena2: "",
      },
      emailReglas: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+/.test(v) || "Email no es valido",
      ],
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Calories", value: "calories" },
        { text: "Fat (g)", value: "fat" },
        { text: "Carbs (g)", value: "carbs" },
        { text: "Protein (g)", value: "protein" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("cliente", ["clientes", "estado", "pruebam"]),
    diplayClass() {
      let classString = "";
      console.log(this.$vuetify.breakpoint.name);
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "subtitle-2 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
    altodinamico() {
      let classString = "altocc-" + this.$vuetify.breakpoint.name;
      return classString;
    },
  },
  async created() {},
  methods: {
    ...mapActions("cliente", ["cambiarclave", "logout"]),
    async cambiar() {
      this.formulario.cargar = true;
      let datos = {
        id: "",
        contrasena: "",
      };
      if (this.estado.logueado) {
        datos.id = this.clientes.id;
        datos.contrasena = this.elem.contrasena;
      }
      await this.cambiarclave(datos);
      if (this.pruebam.coderror == "0") {
        this.iconomensaje = "mdi-check-circle-outline";
        this.titulomensaje = "Cambio de contraseña con exito";
        this.textomensaje =
          "Se realizó el cambio de su contraseña. Ingrese nuevamente.";
        this.colormensaje = "green";
      } else {
        this.iconomensaje = "mdi-chat-alert-outline";
        this.titulomensaje = "Error al realizar el cambio de contraseña";
        this.textomensaje = "Por favor intentelo nuevamente en unos minutos.";
        this.colormensaje = "yellow";
      }
      this.dialog2 = true;

      this.formulario.cargar = false;
    },
    required: function (value) {
      if (value) {
        return true;
      } else {
        return "La contraseña es requerida.";
      }
    },
    min6: function (value) {
      if (value.length >= 6) {
        return true;
      } else {
        return "Su contraseña debe tener más de 6 caractares.";
      }
    },
    validarcontras: function () {
      if (this.elem.contrasena === this.elem.contrasena2) {
        return true;
      } else {
        return "Las contraseñas no coinciden.";
      }
    },
    async cerrar() {
      this.dialog2 = false;
      await this.logout();
      this.$router.push("/login");
    },
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.altocc-xs {
  height: 70vh;
}
.altocc-sm {
  height: 100vh;
}
.altocc-md {
  height: 70vh;
}
.altocc-lg {
  height: 70vh;
}
.altocc-xl {
  height: 70vh;
}
</style>
