<template>
  <div :class="altodinamico">
    <v-row class="mb-5">
      <v-col cols="12" md="12" sm="12" lg="12" xl="12">
        <v-divider></v-divider>
        <div class="pa-4 pl-6">
          <span
            class="rounded amber lighten-2 pa-2 text-body-1 font-weight-medium"
            >Administrar Cuentas</span
          >
        </div>
        <v-row class="pa-6 pt-3">
          <v-col cols="12" md="6" sm="6" lg="3" xl="2">
            <v-hover v-slot="{ hover }">
              <v-expand-transition>
                <v-card
                  class="text-center text-truncate"
                  :color="hover ? 'orange lighten-5' : 'white'"
                  @click="nuevacuenta"
                >
                  <v-card-actions> </v-card-actions>
                  <v-icon large color="blue darken-2">
                    mdi-chart-box-plus-outline
                  </v-icon>
                  <v-card-title :class="diplayClass">Nueva Cuenta</v-card-title>
                  <v-card-text class="text--secondary"
                    >Agregar nueva Cuenta
                  </v-card-text>
                </v-card>
              </v-expand-transition>
            </v-hover>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="pt-3">
          <v-col
            cols="12"
            md="6"
            sm="6"
            lg="3"
            xl="3"
            v-for="(item, index) in this.listaCuentaCliente"
            :key="item.id"
          >
            <v-card class="pt-4 ml-1 mr-1" max-width="400" color="#0A5866" dark>
              <v-card-text class="pb-0 pt-0">
                <div class="pa-2 body-1 font-weight-bold">
                  Cuenta N° {{ index + 1 }}
                </div>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-row>
                        <v-col cols="12" md="12" class="pb-3">
                          <div class="pb-2 font-weight-bold">Perfil</div>
                          <v-chip
                            v-if="item.perfil == 'c'"
                            :color="`blue lighten-4`"
                            class="ml-0 mr-1 black--text"
                            label
                            small
                          >
                            <v-icon dark> mdi-account </v-icon>
                            Persona
                          </v-chip>
                          <v-chip
                            v-else
                            :color="`green lighten-4`"
                            class="ml-0 mr-1 black--text"
                            label
                            small
                          >
                            <v-icon dark> mdi-domain </v-icon>
                            Empresa
                          </v-chip>
                          <span v-if="item.perfil == 'c'">
                            {{ item.datos }}</span
                          >
                          <span v-else> {{ item.razonsocial }}</span>
                        </v-col>
                        <v-col cols="12" md="12" class="pb-0">
                          <v-select
                            v-model="item.banco"
                            :items="tipos"
                            item-text="nombre"
                            item-value="nombre"
                            label="Banco de la cuenta"
                            :rules="[
                              (v) => !!v || 'Seleccione el Banco de su cuenta',
                            ]"
                            outlined
                            readonly
                            class="mb-0"
                          >
                            <template v-slot:item="slotProps">
                              <img
                                :src="slotProps.item.imagen"
                                :class="['mr-2', 'em']"
                              />
                              {{ slotProps.item.banco }}
                            </template>
                            <template #selection="{ item }">
                              <img :src="item.imagen" alt="" />
                              <v-chip color="primary">{{ item.nombre }}</v-chip>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-6 pt-0 mt-0">
                      <v-btn small class="white black--text mr-4 mb-2">
                        {{ item.tipo }}
                      </v-btn>
                      <v-btn class="white black--text mr-4 mb-2" small v-if="item.moneda == 'USD'">
                        <img
                          width="15"
                          height="15"
                          class="mr-2"
                          :src="require('@/assets/eeuu.svg')"
                        />
                        Dolares
                      </v-btn>
                      <v-btn class="white black--text mr-4 mb-2" small v-else>
                        <img
                          width="15"
                          height="15"
                          class="mr-2"
                          :src="require('@/assets/peru.svg')"
                        />
                        Soles
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="item.nrocuenta"
                        label="Número de cuenta"
                        :rules="[(v) => !!v || 'Ingrese el número de cuenta']"
                        prepend-inner-icon="mdi-bank"
                        outlined
                        readonly
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="item.nrocuentainter"
                        label="Sin datos"
                        prepend-inner-icon="mdi-bank-transfer"
                        outlined
                        readonly
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="12" class="pb-0 pt-0">
                      <v-text-field
                        v-model="item.nombrecuenta"
                        label="Nombre de la cuenta"
                        outlined
                        readonly
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click="editarEmpresa(item)"
                  >Modificar</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <cuentabancaria-crear
        ref="dialogoCuentabancariaCrear"
        v-on:actualizaListaCuentas="actualizarLista"
      />
    </v-row>
    <v-row>
      <cuentabancaria-editar ref="dialogoCuentabancariaEditar" />
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import CuentabancariaCrear from "../components/cuentabancaria.crear.vue";
import cuentabancariaEditar from "../components/cuentabancaria.editar.vue";

export default {
  components: {
    CuentabancariaCrear,
    cuentabancariaEditar,
  },
  data: () => ({
    formulario: {
      validar: true,
      cargar: false,
    },
    elem: {
      banco: "",
      nrocuenta: "",
      nrocuentainter: "",
      nombrecuenta: "",
      idcliente: "",
      id: "",
      tipo: "",
      moneda: "",
    },
    listaCuentaCliente: [],
    emailReglas: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+/.test(v) || "Email no es valido",
    ],
    tipos: [
      { nombre: "BCP", imagen: require("@/assets/bcp.png") },
      { nombre: "Interbank", imagen: require("@/assets/interbank.png") },
      { nombre: "Scotiabank", imagen: require("@/assets/scotiabank.png") },
      { nombre: "BBVA", imagen: require("@/assets/bbva.png") },
      { nombre: "Pichincha", imagen: require("@/assets/pichincha.png") },
      { nombre: "Banbif", imagen: require("@/assets/banbif.png") },
      { nombre: "Mibanco", imagen: require("@/assets/mibanco.png") },
      { nombre: "Ripley", imagen: require("@/assets/ripley.png") },
    ],
  }),
  async created() {
    await this.llenarCliente();
    await this.listarCuentasPorCliente(this.clientes.id);
    if (!this.cuentabancariacliente3[0].resultado) {
      this.listaCuentaCliente = this.cuentabancariacliente3;
    } else {
      console.log("no hay datos");
    }
  },
  computed: {
    ...mapState("cliente", ["clientes", "clientesele"]),
    ...mapState("cuentabancaria", ["cuentabancariacliente3"]),
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "subtitle-2 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
    altodinamico() {
      let classString = "altoc-" + this.$vuetify.breakpoint.name;
      return classString;
    },
  },
  methods: {
    ...mapActions("cliente", ["listarClientePorId", "editar"]),
    ...mapActions("cuentabancaria", [
      "listarCuentasPorCliente",
      "asignarCuentabancariaEditar",
      "asignarCuentabancariaAgregarId",
    ]),
    async llenarCliente() {
      await this.listarClientePorId(this.clientes.id);
      //console.log(this.clientesele)
      this.elem.id = this.clientesele.id;
      this.elem.nombres = this.clientesele.nombres;
      this.elem.apaterno = this.clientesele.apaterno;
      this.elem.amaterno = this.clientesele.amaterno;
      this.elem.direccion = this.clientesele.direccion;
      this.elem.email = this.clientesele.email;
      this.elem.celular = this.clientesele.celular;
      this.elem.tipodocumento = this.clientesele.tipodocumento.toUpperCase();
      this.elem.nrodocumento = this.clientesele.nrodocumento;
      this.elem.nacionalidad = this.clientesele.nacionalidad;
      this.elem.pep = this.clientesele.pep.toUpperCase();
      this.elem.ocupacion = this.clientesele.ocupacion;
      this.elem.habilitado = this.clientesele.habilitado;
    },
    nuevacuenta() {
      this.asignarCuentabancariaAgregarId(this.clientes.id);
      this.$refs.dialogoCuentabancariaCrear.mostrarDialogo();
    },
    async editarEmpresa(ele) {
      await this.asignarCuentabancariaAgregarId(this.clientes.id);
      await this.asignarCuentabancariaEditar(ele);
      this.$refs.dialogoCuentabancariaEditar.mostrarDialogo();
    },
    async actualizarLista() {
      await this.listarCuentasPorCliente(this.clientes.id);
      if (!this.cuentabancariacliente3[0].resultado) {
        this.listaCuentaCliente = this.cuentabancariacliente3;
      } else {
        console.log("no hay datos");
      }
    },
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.hidden-sm-and-down {
  color: white !important;
}
.altoc-xs {
  min-height: calc(100vh - 40px);
}
.altoc-sm {
  min-height: calc(100vh - 40px);
}
.altoc-md {
  min-height: calc(100vh - 40px);
}
.altoc-lg {
  min-height: calc(100vh - 40px);
}
.altoc-xl {
  min-height: calc(100vh - 40px);
}
</style>