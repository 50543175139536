<template>
  <div :class="altodinamico">
    <v-row class="pa-5">
      <v-col cols="12" md="5" sm="5" lg="5" xl="5">
        <div class="pa-4 pl-0 pt-1">
          <span
            class="rounded amber lighten-2 pa-2 text-body-1 font-weight-medium"
            >Lista y manejo de cuentas</span
          >
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
    setup() {
        
    },
    computed: {
        altodinamico() {
        let classString = 'altocc-'+ this.$vuetify.breakpoint.name
        return classString
      }
    }
}
</script>