<template>
  <div class="home" style="height: 60vh">
    <v-row>
      <v-col cols="12" md="6" sm="12" lg="8" xl="8">
        <v-row class="pa-5" v-if="habil == 'OK'">
          <v-col cols="12" md="12" sm="6" lg="6" xl="4" class="px-5">
            <v-hover v-slot="{ hover }">
              <v-expand-transition>
                <v-card
                  :to="'/nueva-operacion-efectivo'"
                  class="text-center text-truncate elevation-5"
                  :color="hover ? 'orange lighten-5' : 'white'"
                >
                  <v-card-actions> </v-card-actions>
                  <v-icon large color="teal"> mdi-plus-circle-multiple </v-icon>
                  <v-card-title :class="diplayClass"
                    > <span class="teal--text">Nueva operación en efectivo</span></v-card-title
                  >
                  <v-card-text class="text--secondary"
                    >Operación de compra y venta en efectivo </v-card-text
                  >
                </v-card>
              </v-expand-transition>
            </v-hover>
          </v-col>
        </v-row>
        <v-row class="pa-5">
          <v-col cols="12" md="12" sm="6" lg="6" xl="4" class="px-5">
            <v-hover v-slot="{ hover }">
              <v-expand-transition>
                <v-card
                  :to="'/nueva-operacion'"
                  class="text-center text-truncate elevation-5"
                  :color="hover ? 'orange lighten-5' : 'white'"
                >
                  <v-card-actions> </v-card-actions>
                  <v-icon large color="blue darken-2"> mdi-plus-box </v-icon>
                  <v-card-title :class="diplayClass"
                    >Nueva operación</v-card-title
                  >
                  <v-card-text class="text--secondary"
                    >Realice una nueva cotización</v-card-text
                  >
                </v-card>
              </v-expand-transition>
            </v-hover>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
        sm="12"
        lg="4"
        xl="4"
        align="center"
        justify="center"
      >
        <v-card class="mt-2" max-width="344">
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <div class="d-inline pa-2 orange accent-4 white--text">
                  Compra
                </div>
                <div class="d-inline pa-2 pl-4 pr-4 black white--text">
                  {{ elem.compra }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-inline pa-2 orange accent-4 white--text">
                  Venta
                </div>
                <div class="d-inline pa-2 pl-4 pr-4 black white--text">
                  {{ elem.venta }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2" :color="'orange lighten-3'" max-width="344">
          <v-card-text>
            <div class="text--primary subtitle-2">
              Operaciones Mayores a 10,000 Dolares
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              rounded
              color="white"
              href="https://api.whatsapp.com/send?phone=51997327111"
              target="_blank"
            >
              Consulte aqui
              <v-icon right color="green"> mdi-message-arrow-right </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="pe-5">
      <v-col cols="12" class="text-right">
        <v-btn
          color="green accent-4"
          fixed
          dark
          bottom
          right
          fab
          href="https://api.whatsapp.com/send?phone=51997327111"
          target="_blank"
          class="mb-3"
        >
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "Home",
  data: () => ({
    elem: {
      compra: "",
      venta: ""
    },
    habil: "",
  }),
  components: {},
  computed: {
    ...mapState("tipocambio", ["tipocambioultimo"]),
    ...mapState("cliente", ["clientes", "estado"]),
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "h5 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
  },
  async created() {
    await this.listarUltimoTipocambio("11-11-21");
    this.elem.compra = this.tipocambioultimo[0].compra;
    this.elem.venta = this.tipocambioultimo[0].venta;
    if (this.estado.logueado) {
      this.habil = this.clientes.habil
    }
  },
  methods: {
    ...mapActions("tipocambio", ["listarUltimoTipocambio"]),
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>
