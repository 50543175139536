import router from '../router'
import { direccion } from '../_extra/configuracion'

const clientes = JSON.parse(localStorage.getItem('cliente'))

const state = clientes 
    ? { estado: { logueado: true }, clientes,  clientesele:null,pruebam:'', clienteEditar: [], resultado: {}, exptime: {} }
    : { estado: {}, clientes:null, clientesele:null, pruebam:'', exptime: null }

const actions = {
    async login ({dispatch, commit},{email, contrasena}) {
        commit('loginPedido', {email})
        const pedidoOpciones = {
            method: 'POST',
            headers: { 'Content-type':'application/json' },
            body: JSON.stringify({ email, contrasena})
        }
        try {
            //console.log(JSON.stringify({ email, contrasena}))
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ag001`, pedidoOpciones)
            const contenido = await respuesta.json()
            //console.log(contenido)
            if(contenido.nombres != null) {
                localStorage.setItem('cliente', JSON.stringify(contenido))
                await commit('loginExitoso', contenido)
                router.push('/')
            } else {
                commit('loginFallido', 'Usuario y/o contraseña incorrectos')
                dispatch('alerta/error', 'Usuario y/o contraseña incorrectos', {root:true})
            }
        } catch (error) {
            console.error(error)
        }
    },
    logout({commit}) {
        localStorage.removeItem('cliente')
        //console.log("primer: " + state.estado.logueado)
        commit('logout')
    },
    prueba({commit}) {
        console.log("primer: ")
        commit('prubamu')
    },
    async listarClientePorId({commit}, idcliente) {
        const opciones = {
            method: 'GET',
        }
        try {
            //console.log(idcliente)
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=li001&id=${idcliente}`, opciones)
            const contenido = await respuesta.json()
            //console.log(contenido)
            commit('obtenerClientePorId', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editar({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ed002`, opciones)
            const contenido = await respuesta.json()
            commit('editarCliente', contenido);
        } catch (error) {
            console.error(error)
        }
    },
    async cambiarclave({commit}, elementoEditar) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoEditar)
        }
        try {
            console.log(elementoEditar)
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ed003`, opciones)
            const contenido = await respuesta.json()
            commit('cambiarclaveCliente', contenido);
        } catch (error) {
            console.error(error)
        }
    },
    async traerClienteId({commit}, idcliente) {
        const opciones = {
            method: 'GET',
        }
        try {
            //console.log(idcliente)
            let respuesta = await fetch(`${direccion().url}/clientes.php?accion=li003&id=${idcliente}`, opciones)
            const contenido = await respuesta.json()
            //console.log(contenido)
            commit('obtenerClienteId', contenido)
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    loginPedido(state, cliente) {
        state.clientes = cliente
    },
    loginExitoso(state, cliente) {
        state.estado = {logueado:true}
        state.clientes = cliente
    },
    loginFallido(state) {
        state.estado = {}
        state.clientes = null
    },
    logout(state) {
        //state.estado = { logueado = false}
        state.clientes = null
        state.estado.logueado = false
    },
    obtenerClientePorId(state, cliente) {
        state.clientesele = cliente
    },
    editarCliente(state, contenido) {
        state.resultado = contenido
    },
    cambiarclaveCliente(state, respuesta) {
        state.pruebam = respuesta
    },
    pruebamu(state){
        state.pruebam = 'hoola'
        console.log(state.pruebam)
    },
    obtenerClienteId(state, contenido) {
        state.all = {resultado : contenido}
        state.clienteEditar = contenido
    },
}

export const cliente = {
    namespaced: true,
    state,
    actions,
    mutations
}