
<template>
  <div >
    <v-data-table
      :headers="computedHeaders"
      :items="operacionesefectivo"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      class="elevation-1"
      :header-props="headerProps"
      :footer-props="{
        'items-per-page-options': [5, 10, -1],
        'items-per-page-text': 'Por pagina',
        'items-per-page-all-text': 'Todo',
      }"
    >
      <template v-slot:top>
        <v-dialog v-model="dialogEditar" max-width="300px">
          <v-card>
            <v-card-title>
              <span class="text-h5 headerClass"
                >Enviar Codigo de operación</span
              >
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="elem.verificacion"
                      label="Codigo de verificacion"
                      :disabled="isDisabled"
                    ></v-text-field>
                    <span class="green--text" v-show="mostrarmensaje"
                      >Ya se completo con exito la operación de cambio.
                      Gracias.</span
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cerrar"> Salir </v-btn>
              <v-btn
                color="blue darken-1"
                text
                :disabled="!validar"
                :loading="loading"
                @click="modificar"
              >
                Modificar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAnular" max-width="400px">
          <v-card>
            <v-avatar :color="'red'">
              <v-icon dark> mdi-alert </v-icon>
            </v-avatar>
            <v-card-title class="text-h5 justify-center headerClass">
              ¿Deseas anular la siguiente operación {{elemAnular.nro}}?
            </v-card-title>
            <v-card-text
              class="pt-4 text-body-1 font-weight-medium text-justify"
              >Un vez la operación se anule no se podra reestablecer. Pero puede
              seguir realizando más operaciones de cambio de dinero.
            </v-card-text>
            <v-card-actions>
              <v-btn 
                color="black darken-1"  
                text 
                @click="dialogAnular = false">
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn 
                color="blue darken-1"  
                text 
                :disabled="!validarAnular"
                :loading="loadingAnular"
                @click="anularOperacion">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.cliente`]="{ item }">
        <div>
          <v-chip
            v-if="item.perfil == 'c'"
            :color="`blue lighten-4`"
            class="ml-0 mr-0 black--text"
            label
            small
          >
            <v-icon dark small> mdi-account </v-icon>
            Persona
          </v-chip>
          <v-chip
            v-else
            :color="`green lighten-4`"
            class="ml-0 mr-0 black--text"
            label
            small
          >
            <v-icon dark small> mdi-domain </v-icon>
            Empresa
          </v-chip>
          <div class="font-weight-medium mt-1">{{ item.cliente }}</div>
        </div>
      </template>
      <template v-slot:[`item.monedarecepcion`]="{ item }">
        <div class="text-center">
           <img
            :src="
              require('@/assets/' +
                item.monedarecepcion.toLowerCase() +
                '-flag.svg')
            "
            :class="['mr-2', 'em', 'cuentas-size']"
          />  {{item.monedarecepcion}}
        </div>
      </template>
      <template v-slot:[`item.monedaenvio`]="{ item }">
        <div class="text-center">
           <img
            :src="
              require('@/assets/' +
                item.monedaenvio.toLowerCase() +
                '-flag.svg')
            "
            :class="['mr-2', 'em', 'cuentas-size']"
          />  {{item.monedaenvio}}
        </div>
      </template>
      <template v-slot:[`item.desde`]="{ item }">
        <div class="text-end">
          {{ item.montodesde }}
        </div>
      </template>
      <template v-slot:[`item.hacia`]="{ item }">
        <div class="text-end">
          {{ item.montohacia }}
        </div>
      </template>
      <template v-slot:[`item.tipocambio`]="{ item }">
        <div class="text-end">
          {{ item.tipocambio }}
        </div>
      </template>
      <template v-slot:[`item.llego`]="{ item }">
        <div class="text-center">
           {{ item.monedallego }}
        </div>
      </template>
      <template v-slot:[`item.estado`]="props">
        <v-chip :color="getColor(props.item.estado)" dark>
          {{ props.item.estado }}
        </v-chip>
      </template>
      <template
        v-slot:[`footer.page-text`]="{ pageStart, pageStop, itemsLength }"
      >
        {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
      </template>
      <template v-slot:no-data> No hay registros </template>
    </v-data-table>
    <v-row justify="center">
      <v-dialog v-model="dialog2" max-width="450">
        <v-card>
          <v-avatar :color="colormensaje">
            <v-icon dark>
              {{ iconomensaje }}
            </v-icon>
          </v-avatar>
          <v-card-title class="text-h5 justify-center headerClass">
            {{ titulomensaje }}
          </v-card-title>
          <v-card-text class="pt-4 text-body-1 font-weight-medium text-justify"
            >{{ textomensaje }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog2 = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "listatodooperacionesefectivo",
  components: {},
  data() {
    return {
      sortBy: "id",
      sortDesc: true,
      textomensaje: "",
      iconomensaje: "",
      titulomensaje: "",
      colormensaje: "",
      dialog2: false,
      mostrarmensaje: false,
      isDisabled: false,
      loading: false,
      validar: true,
      loadingAnular: false,
      validarAnular: true,
      dialogEditar: false,
      dialogAnular: false,
      formulario: {
        validar: true,
        cargar: false,
      },
      elem: {
        estado: "",
        verificacion: "-",
        id: "",
        nombre: "",
        nro: "",
        email: "",
      },
      elemAnular: {
        nro: '',
        email: '',
        nombres: '',
        id: ''
      },
      idcl: "",
      emailReglas: [
        (v) => !!v || "Email es requerido",
        (v) => /.+@.+/.test(v) || "Email no es valido",
      ],
      headers: [
        {
          text: "id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Nro", value: "nro" },
        { text: "Tipo", value: "tipo" },
        { text: "Perfil / Cliente", value: "cliente" },
        { text: "Fecha", value: "fecharegistro" },
        { text: "Moneda de envio", value: "monedaenvio" },
        { text: "Monto de envio", value: "desde" },
        { text: "Moneda de recepcion", value: "monedarecepcion" },
        { text: "Monto de recepcion", value: "hacia" },
        { text: "tipo Cambio", value: "tipocambio" },
        { text: "Estado", value: "estado" },
        { text: "Acciones", value: "acciones" },
      ],
      items: ["registrado", "comprobando", "autorizado", "cancelado"],
      desserts: [],
      headerProps: {
        sortByText: "Ordenar por:"
      },
    };
  },
  computed: {
    ...mapState("operacion", ["operacionesefectivo", "oresultado"]),
    ...mapState("cliente", ["estado", "clientes"]),
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "subtitle-2 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
    computedHeaders() {
      return this.headers.filter((header) => header.text !== "id");
    },
  },
  watch: {
    dialogEditar(val) {
      val || this.cerrar();
    },
  },
  async created() {
    if (this.estado.logueado) {
      this.idcl = this.clientes.id;
    }
    await this.listarTodoClienteOperacionesEfectivo(this.idcl);
  },
  methods: {
    ...mapActions("operacion", [
      "listarTodoClienteOperacionesEfectivo",
      "editarEstadoCodigo",
      "anularOperacionDinero",
    ]),
    async guardar() {
      this.formulario.cargar = true;
      //console.log(this.elem);
      await this.agregar(this.elem);
      await this.listarUltimoTipocambio();
      console.log(this.tipocambioultimo);
      this.formulario.cargar = false;
      this.dialogoAbrir = false;
    },
    getColor(estado) {
      if (estado == "registrado") return "blue";
      else if (estado == "comprobando") return "cyan";
      else if (estado == "autorizado") return "green accent-4";
      else if (estado == "cancelado" || estado == "anulado" || estado == "expiro") return "red";
      else return "blue";
    },
    getSelectionStart(o) {
      if (o.createTextRange) {
        var r = document.selection.createRange().duplicate();
        r.moveEnd("character", o.value.length);
        if (r.text == "") return o.value.length;
        return o.value.lastIndexOf(r.text);
      } else return o.selectionStart;
    },
    editItem(item) {
      this.elem.verificacion = item.verificacion;
      this.elem.estado = item.estado;
      this.elem.id = item.id;
      this.elem.nro = item.nro;
      this.elem.nombre = item.cliente.split(" ")[0];
      this.elem.email = item.email;
      if (
        this.elem.estado === "autorizado" ||
        this.elem.estado === "cancelado"
      ) {
        this.isDisabled = true;
        this.mostrarmensaje = true;
        this.validar = false;
      } else {
        this.isDisabled = false;
        this.mostrarmensaje = false;
        this.validar = true;
      }
      this.dialogEditar = true;
    },
    cerrar() {
      this.dialogEditar = false;
    },
    async modificar() {
      this.validar = false;
      this.loading = true;
      if (this.elem.verificacion == undefined) {
        this.elem.verificacion = "0";
      }
      await this.editarEstadoCodigo(this.elem);
      //console.log(this.oresultado);
      if (this.oresultado.resultado.coderror == "0") {
        this.iconomensaje = "mdi-check-circle-outline";
        this.titulomensaje = "Se registro el codigo con exito";
        this.textomensaje =
          "Gracias por registrar su operación, en los proximos minutos recibira el dinero y la confirmación de envio.";
        this.colormensaje = "green";
      } else {
        this.iconomensaje = "mdi-chat-alert-outline";
        this.titulomensaje = "Problemas de envio";
        this.textomensaje =
          "Por favor ingrese nuevamente el codigo de operación, y envielo.";
        this.colormensaje = "yellow";
      }
      await this.listarTodoClienteOperacionesEfectivo(this.idcl);
      this.dialog2 = true;

      this.loading = false;
      this.validar = true;
      this.cerrar();
    },
    anularItem(item){
      //console.log(item)
      this.elemAnular.email = item.email
      this.elemAnular.nro = item.nro
      this.elemAnular.nombres = item.cliente
      this.elemAnular.id = item.id

      this.dialogAnular = true
    },
    async anularOperacion(){
      this.validarAnular = false;
      this.loadingAnular = true;
      console.log(this.elemAnular)
      await this.anularOperacionDinero(this.elemAnular)

      this.validarAnular = true;
      this.loadingAnular = false;
      this.dialogAnular = false
      await this.listarTodoClienteOperacionesEfectivo(this.idcl);

    }
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.headerClass {
  word-break: normal;
}
</style>