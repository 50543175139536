<template>
  <div>
    <div class="pa-4 pl-6 pb-1">
      <span class="rounded amber lighten-2 pa-2 text-body-1 font-weight-medium"
        >Administrar Perfiles</span
      >
      <div class="text-body-2 py-4 ms-1">Lista de perfiles</div>
    </div>
    <div class="pa-1 pl-6">
      <v-row>
        <v-col cols="12">
          <v-btn depressed color="primary" @click="crearListado()">
            Generar
            <v-icon dark right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="fill-height"
        align-content="center"
        justify="center"
        v-if="cargarReporte"
      >
        <v-col class="text-subtitle-1 text-center dark" cols="12">
          Cargando las cuentas...
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary"
            rounded
            height="6"
            :active="cargarReporte"
            :indeterminate="cargarReporte"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(item, i) in listaContactoEmpresas"
          :key="i"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card>
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <div class="text-overline ps-4 pt-3 pb-0 primary--text">
                  {{ item["tipo"] }}
                </div>
                <v-card-title
                  class="text-body-1 py-2 pt-0 black--text"
                >
                {{ item['datos'] }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-subtitle
                  class="py-0 pt-2"
                >
                {{item['tipodocumento']}}
                </v-card-subtitle>
                <v-card-subtitle
                  class="py-0 pt-0"
                >
                {{item['nrodocumento']}}
                </v-card-subtitle>
              </div>
              <div
                class="d-flex flex-column pe-5 pt-5"
                style="align-items: end"
              >
                <div
                  class="mt-5 d-flex flex-column align-center"
                  style="align-items: center !important"
                >
                  <h2 class="d-flex justify-end">
                    <v-icon
                      x-large
                      color="primary"
                      v-if="item['tipo'] == 'empresa'"
                    >
                      mdi-domain
                    </v-icon>
                    <v-icon x-large color="primary" v-else>
                      mdi-account
                    </v-icon>
                  </h2>
                </div>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn
                class="ma-2 mt-3 mb-2 px-4 py-4 white--text"
                small
                color="success"
                @click="verItem(item)"
              >
                Editar Perfil
                <v-icon dark right>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <v-row class="pt-5">
      <v-col cols="12" md="6" sm="12" lg="8" xl="8">
        <v-divider></v-divider>
        <div class="pa-4 pl-6">
          <span
            class="rounded amber lighten-2 pa-2 text-body-1 font-weight-medium"
            >Crear perfiles de empresas</span
          >
        </div>
        <v-row class="pa-6 pt-3">
          <v-col cols="12" md="12" sm="6" lg="4" xl="3">
            <v-hover v-slot="{ hover }">
              <v-expand-transition>
                <v-card
                  class="text-center text-truncate"
                  :color="hover ? 'orange lighten-5' : 'white'"
                  @click="nuevaempresa"
                >
                  <v-card-actions> </v-card-actions>
                  <v-icon large color="blue darken-2">
                    mdi-account-multiple-plus
                  </v-icon>
                  <v-card-title :class="diplayClass">Nuevo Perfil</v-card-title>
                  <v-card-text class="text--secondary"
                    >Agregar perfil de empresa
                  </v-card-text>
                </v-card>
              </v-expand-transition>
            </v-hover>
          </v-col>

          <v-col
            cols="12"
            md="12"
            sm="6"
            lg="4"
            xl="3"
            v-for="(item, index) in this.listaEmpresaCliente"
            :key="item.id"
          >
            <v-card class="mx-auto" max-width="344" color="#0D5763" dark>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-caption mb-1">Perfil {{ index + 1 }}</div>
                  <v-list-item-subtitle class="mb-2">{{
                    item.ruc
                  }}</v-list-item-subtitle>
                  <div>{{ item.razonsocial }}</div>
                </v-list-item-content>
              </v-list-item>

              <v-card-actions>
                <v-btn outlined rounded text @click="editarEmpresa(item)">
                  Editar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <empresa-crear ref="dialogoEmpresaCrear" @force-render="forceRenderEmpresaCrear"/>
    </v-row>
    <v-row>
      <empresa-editar ref="dialogoEmpresaEditar" @force-render="forceRenderEmpresa" />
    </v-row>
    <v-row>
      <persona-editar ref="dialogoPersonaEditar"  @force-render="forceRender" />
    </v-row>
    <v-row>
      <v-snackbar 
      v-model="snackbar.modelo" 
      :timeout="snackbar.timeout" 
      top
      :color="snackbar.color"
      center>
        {{snackbar.texto}}

        <template v-slot:action="{ attrs }">
          <v-btn  text v-bind="attrs" @click="snackbar.modelo = false">
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import EmpresaCrear from "../components/empresa.crear.vue";
import EmpresaEditar from "../components/empresa.editar.vue";
import PersonaEditar from "../components/persona.editar.vue";

export default {
  components: {
    EmpresaCrear,
    EmpresaEditar,
    PersonaEditar,
  },
  data: () => ({
    snackbar: {
      modelo: false,
      timeout: 1300,
      color: "success",
      texto: "Se actualizó el registro correctamente."
    },
    listaContactoEmpresas: [],
    cargarReporte: false,
    formulario: {
      validar: true,
      cargar: false,
    },
    elem: {
      nombres: "",
      apaterno: "",
      amaterno: "",
      direccion: "",
      email: "",
      celular: "",
      tipodocumento: "",
      nrodocumento: "",
      nacionalidad: "",
      pep: "",
      ocupacion: "",
      habilitado: "",
    },
    elemEmpresa: {
      id: "",
      razonsocial: "",
      ruc: "",
      direccion: "",
      email: "",
      telefono: "",
      pais: "",
      gironegocio: "",
      habilitado: "SI",
      idcliente: "",
    },
    listaEmpresaCliente: [],
    emailReglas: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+/.test(v) || "Email no es valido",
    ],
    tipos: [
      "SIN DOCUMENTO",
      "DNI",
      "RUC",
      "CARNET EXTRANJERIA",
      "CÉDULA CIUDADANA",
      "PASAPORTE",
      "CÉDULA DIPLOMATICA",
    ],
    peps: ["SI", "NO"],
  }),
  async created() {
    await this.llenarCliente();
    /*await this.listarEmpresasPorCliente(this.elem.id);
    if (!this.empresacliente[0].resultado) {
      this.listaEmpresaCliente = this.empresacliente;
      this.elemEmpresa = this.empresacliente[0];
    } else {
      console.log("no hay datos");
    }*/
    await this.listarContactoEmpresas(this.elem.id);
    this.listaContactoEmpresas = this.contactoempresas;
    console.log(this.listaContactoEmpresas)
  },
  computed: {
    ...mapState("cliente", ["clientes", "clientesele", "resultado", "clienteEditar", "exptime"]),
    ...mapState("empresa", ["empresacliente", "contactoempresas", "resultadoEmpresa"]),
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "subtitle-2 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
  },
  methods: {
    ...mapActions("cliente", [
      "listarClientePorId",
      "editar",
      "traerClienteId",
    ]),
    ...mapActions("empresa", [
      "listarEmpresasPorCliente",
      "asignarEmpresaEditar",
      "listarContactoEmpresas",
      "traerDatosEmpresa",
    ]),
    async check(){
      
    },
    async llenarCliente() {
      await this.listarClientePorId(this.clientes.id);
      //console.log(this.clientesele)
      this.elem.id = this.clientesele.id;
      this.elem.nombres = this.clientesele.nombres;
      this.elem.apaterno = this.clientesele.apaterno;
      this.elem.amaterno = this.clientesele.amaterno;
      this.elem.direccion = this.clientesele.direccion;
      this.elem.email = this.clientesele.email;
      this.elem.celular = this.clientesele.celular;
      this.elem.tipodocumento = this.clientesele.tipodocumento.toUpperCase();
      this.elem.nrodocumento = this.clientesele.nrodocumento;
      this.elem.nacionalidad = this.clientesele.nacionalidad;
      this.elem.pep = this.clientesele.pep.toUpperCase();
      this.elem.ocupacion = this.clientesele.ocupacion;
      this.elem.habilitado = this.clientesele.habilitado;
    },
    async modificar() {
      this.formulario.cargar = true;
      await this.editar(this.elem);
      await this.llenarCliente();
      this.formulario.cargar = false;
    },
    nuevaempresa() {
      this.$refs.dialogoEmpresaCrear.mostrarDialogo();
    },
    editarEmpresa(ele) {
      //console.log(ele)
      this.asignarEmpresaEditar(ele);
      this.$refs.dialogoEmpresaEditar.mostrarDialogo();
    },
    async forceRender(){
      if(this.resultado.coderror == '1'){
        this.snackbar.color = "warning"
        this.snackbar.timeout = 2500
        this.snackbar.texto = "Advertencia: Ya existe el correo electrónico en el registro."
      } else if(this.resultado.coderror == '2'){
        this.snackbar.color = "warning"
        this.snackbar.timeout = 2500
        this.snackbar.texto = "Advertencia: Ya existe el número de documento en el registro."
      } else {
        this.snackbar.color = "success"
        this.snackbar.timeout = 1300
        this.snackbar.texto = "Se actualizó el registro correctamente."
      }
      this.cargarReporte = true;
      this.snackbar.modelo = true
      await this.listarContactoEmpresas(this.elem.id);
      this.listaContactoEmpresas = this.contactoempresas;
      this.cargarReporte = false;
    },
    async forceRenderEmpresa(){
      if(this.resultadoEmpresa.coderror == '1'){
        this.snackbar.color = "warning"
        this.snackbar.timeout = 2500
        this.snackbar.texto = "Advertencia: Ya existe el RUC en el registro."
      }  else {
        this.snackbar.color = "success"
        this.snackbar.timeout = 1300
        this.snackbar.texto = "Se actualizó el registro correctamente."
      }
      this.cargarReporte = true;
      this.snackbar.modelo = true
      await this.listarContactoEmpresas(this.elem.id);
      this.listaContactoEmpresas = this.contactoempresas;
      this.cargarReporte = false;
    },
    async forceRenderEmpresaCrear(){
      if(this.resultadoEmpresa.coderror == '1'){
        this.snackbar.color = "warning"
        this.snackbar.timeout = 2500
        this.snackbar.texto = "Advertencia: El RUC ya existe en nuestra central, intentelo nuevamente."
      }  else {
        this.snackbar.color = "success"
        this.snackbar.timeout = 1300
        this.snackbar.texto = "Se ha creado el perfil de Empresa con éxito!"
      }
      this.cargarReporte = true;
      this.snackbar.modelo = true
      await this.listarContactoEmpresas(this.elem.id);
      this.listaContactoEmpresas = this.contactoempresas;
      this.cargarReporte = false;
    },
    async crearListado() {      
      this.cargarReporte = true;
      await this.listarContactoEmpresas(this.elem.id);
      this.listaContactoEmpresas = this.contactoempresas;
      this.cargarReporte = false;
    },
    async verItem(item) {
      if (item.tipo === "empresa") {
        await this.traerDatosEmpresa(item.id);
        this.$refs.dialogoEmpresaEditar.mostrarDialogo();
      } else {
        await this.traerClienteId(item.id);
        this.elem = this.clienteEditar[0]
        this.$refs.dialogoPersonaEditar.mostrarDialogo();
      }
    },
  },
};
</script>
<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
</style>