<template>
  <v-dialog v-model="dialogoAbrir" max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline text-h6">Agregar Perfil de Empresa</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="formEmpresa" v-model="formulario.validar">
          <v-container class="mt-5">
            <v-row>
              <v-col cols="12" class="pb-5 pt-0">
                <div class="text-start black--text font-weight-bold">Datos de la Empresa</div>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  v-model="elem.ruc"
                  label="RUC"
                  :rules="[
                    (v) => !!v || 'El RUC es requerido',
                    (v) =>
                      v.length >= 11 || 'El RUC debe de tener 11 caracteres',
                  ]"
                  maxlength="11"
                  outlined
                  counter
                  required
                  dense
                  ref="focusdocumento"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  v-model="elem.razonsocial"
                  label="Razón social"
                  :rules="[(v) => !!v || 'La razón social es requerida']"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  v-model="elem.telefono"
                  label="Teléfono"
                  :rules="[
                    (v) => !!v || 'El el número de telefono es requerido',
                  ]"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  v-model="elem.direccion"
                  label="Dirección"
                  :rules="[(v) => !!v || 'El dirección es requerida']"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-select
                  v-model="elemUbigeo.departamento"
                  :items="listadepartamentos"
                  item-text="nombre_ubigeo"
                  item-value="id_ubigeo"
                  label="Departamento"
                  return-object
                  :rules="[
                    (v) => !!v || 'Seleccione el departamento registrado',
                  ]"
                  @change="cambiarDepartamento"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-select
                  v-model="elemUbigeo.provincia"
                  :items="listaprovincias"
                  item-text="nombre_ubigeo"
                  item-value="id_ubigeo"
                  label="Provincia"
                  return-object
                  :rules="[(v) => !!v || 'Seleccione la provincia registrada']"
                  @change="cambiarProvicia"
                  outlined
                  dense
                >
                  <template v-slot:no-data>
                    <div class="px-4 pb-3 mt-4">
                      Seleccione el departamento...
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-select
                  v-model="elemUbigeo.distrito"
                  :items="listadistritos"
                  item-text="nombre_ubigeo"
                  item-value="id_ubigeo"
                  label="Distrito"
                  return-object
                  :rules="[(v) => !!v || 'Seleccione el distrito registrado']"
                  @change="cambiarDistrito"
                  outlined
                  dense
                >
                  <template v-slot:no-data>
                    <div class="px-4 pb-3 mt-4">
                      Seleccione el departamento y la provincia...
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  v-model="elem.gironegocio"
                  label="Actividad económica principal"
                  :rules="[(v) => !!v || 'Ingrese su actividad económica']"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-5 pt-0">
                <div class="text-start black--text font-weight-bold">Datos del Representante legal</div>
              </v-col>
              <v-col cols="12" md="6" class="pb-0 pt-0">
                <v-select
                  v-model="elem.rptipodocumento"
                  :items="tipos"
                  label="Tipo de documento"
                  :rules="[(v) => !!v || 'Seleccione el tipo de documento']"
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" class="pb-0 pt-0" md="6">
                <v-text-field
                  v-model="elem.rpnrodocumento"
                  label="Número de documento"
                  :rules="[(v) => !!v || 'El número es requerido']"
                  outlined
                  required
                  dense
                  ref="focusdocumentoRP"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  v-model="elem.rpnombres"
                  label="Nombres"
                  :rules="[(v) => !!v || 'El nombre es requerido']"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  v-model="elem.rpapaterno"
                  label="Apellido Paterno"
                  :rules="[(v) => !!v || 'El apellido parterno es requerido']"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-text-field
                  v-model="elem.rpamaterno"
                  label="Apellido Materno"
                  :rules="[(v) => !!v || 'El apellido materno es requerido']"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0 pt-0">
                <v-select
                  v-model="elem.rpocupacion"
                  :items="ocupaciones"
                  label="Ocupación"
                  :rules="[(v) => !!v || 'La ocupación es requerida']"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!formulario.validar"
          :loading="formulario.cargar"
          @click="agregarempresa"
          >Agregar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { ubigeo } from "../_extra/ubigeo";

export default {
  name: "empresa.crear",
  data: () => ({
    dialogoAbrir: false,
    formulario: {
      validar: true,
      cargar: false,
    },
    elem: {
      id: "",
      razonsocial: "",
      ruc: "",
      direccion: "",
      telefono: "",
      pais: "",
      gironegocio: "",
      habilitado: "SI",
      departamento: "",
      provincia: "",
      distrito: "",
      rptipodocumento: "DNI",
      rpnrodocumento: "",
      rpnombres: "",
      rpapaterno: "",
      rpamaterno: "",
      rpocupacion: "",
    },
    elemUbigeo: {
      departamento: "",
      provincia: "",
      distrito: "",
    },
    tipos: [
      "DNI",
      "RUC",
      "CARNET EXTRANJERIA",
      "CÉDULA CIUDADANA",
      "PASAPORTE",
      "CÉDULA DIPLOMATICA",
    ],
    ocupaciones: [
      "Abogado (a)",
      " Administrador(a)",
      "Agricultor(a)",
      "Ama de casa",
      "Arquitecto(a)",
      "Artista",
      "Cambista",
      "Comerciante",
      "Consultor(a)",
      "Contador(a)",
      "Diseñador(a)",
      "Docente",
      "Economista",
      "Empresario(a)",
      "Enfermero(a)",
      "Estudiante",
      "Funcionario Público",
      "Importador o exportador",
      "Ingeniera(o)",
      "Ingeniera(o)",
      "Jubilado",
      "Médico",
      "Militar",
      "Obstetriz",
      "Odontólogo(a)",
      "Periodista",
      "Policía",
      "Psicólogo(a)",
      "Publicista",
      "Seguridad",
      "Trabajador dependiente",
      "Trabajador Independiente",
      "Trabajador(a) Social",
      "Vendedor(a)",
      "Otros",
    ],
    listadepartamentos: [],
    listaprovincias: [],
    listadistritos: [],
    emailReglas: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+/.test(v) || "Email no es valido",
    ],
  }),
  created() {
    
  },
  computed: {
    ...mapState("cliente", ["clientes", "clientesele"]),
    ...mapState("empresa", ["all", "empresaEditar", "resultado"]),
  },
  methods: {
    ...mapActions("empresa", ["agregar", "listarEmpresasPorCliente"]),
    async mostrarDialogo() {
      this.cargarDepartamento()
      //console.log(this.empresaEditar)
      this.dialogoAbrir = true;

      setTimeout(() => {
        this.$refs.focusdocumento.focus();
      }, 200);
      //await this.listarEmpresasPorCliente(this.clientesele.id)
    },
    async agregarempresa() {
      this.formulario.cargar = true;
      if (this.elemUbigeo.departamento == null) {
        this.elem.departamento = "";
      } else {
        this.elem.departamento = this.elemUbigeo.departamento.nombre_ubigeo;
      }
      if (this.elemUbigeo.departamento == null) {
        this.elem.provincia = "";
      } else {
        this.elem.provincia = this.elemUbigeo.provincia.nombre_ubigeo;
      }
      if (this.elemUbigeo.distrito == null) {
        this.elem.distrito = "";
      } else {
        this.elem.distrito = this.elemUbigeo.distrito.nombre_ubigeo;
      }

      this.elem.idcliente = this.clientes.id
      
      await this.agregar(this.elem);
      this.$emit('force-render')
      this.formulario.cargar = false;
      this.dialogoAbrir = false;
    },
    cargarDepartamento() {
      this.listadepartamentos = ubigeo().resultado.departamentos;
      this.listaprovincias = [];
      this.listadistritos = [];
    },
    cambiarDepartamento(item) {
      this.listaprovincias = ubigeo().resultado.provincias[item.id_ubigeo];
      this.listadistritos = [];
      this.elem.departamento = item.nombre_ubigeo;
    },
    cambiarProvicia(item) {
      this.listadistritos = ubigeo().resultado.distritos[item.id_ubigeo];
      this.elem.provincia = item.nombre_ubigeo;
    },
    cambiarDistrito(item) {
      this.elem.distrito = item.nombre_ubigeo;
    },
  },
};
</script>