import { direccion } from '../_extra/configuracion'

const state = {
    all: {},
    tipocambio: [],
    tipocambioultimo: [],
    tipocambioultimoefectivo: []
}

const actions = {
    async listarTipocambio({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/tipocambio.php`, opciones)
            const contenido = await response.json()
            commit('obtenerTipocambio', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarUltimoTipocambio({commit}, fecha) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/tipocambio.php?fecha=${fecha}`, opciones)
            const contenido = await response.json()
            commit('obtenerUltimoTipocambio', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarUltimoTipocambioEfectivo({commit}, fecha) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/tipocambio.php?fechaefectivo=${fecha}`, opciones)
            const contenido = await response.json()
            commit('obtenerUltimoTipocambioEfectivo', contenido)
        } catch (error) {
            console.error(error)
        }
    },
}

const mutations = {
    obtenerTipocambio(state, contenido) {
        state.all = {resultado : contenido}
        state.tipocambio = contenido
    },
    obtenerUltimoTipocambio(state, contenido) {
        state.all = {resultado : contenido}
        state.tipocambioultimo = contenido
    },
    agregarTipocambio(state, contenido) {
        state.all = {resultado : contenido}
    },
    obtenerUltimoTipocambioEfectivo(state, contenido) {
        state.all = {resultado : contenido}
        state.tipocambioultimoefectivo = contenido
    },
}

export const tipocambio = {
    namespaced: true,
    state,
    actions,
    mutations
}