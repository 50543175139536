<template>
  <v-dialog v-model="dialogoAbrir" max-width="450px">
    <v-card>
      <v-card-title>
        <span class="headline">Nueva Cuenta</span>
      </v-card-title>
      <v-card-text>
        <v-form ref="formCuenta" v-model="formulario.validar">
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="elemPerfil"
                      :items="listaContactoEmpresas"
                      item-text="datos"
                      item-value="id"
                      label="Perfil"
                      return-object
                      :rules="[
                        (v) =>
                          !!v ||
                          'Seleccione el perfil de cuenta (Persona o empresas)',
                      ]"
                      @change="cambiarPerfil"
                      ref="FocusOrderDelivery"
                    >
                      <template v-slot:item="slotProps">
                        <v-chip
                          v-if="slotProps.item.tipo == 'contacto'"
                          :color="`blue lighten-4`"
                          class="ml-0 mr-1 black--text"
                          label
                          small
                        >
                          <v-icon dark> mdi-account </v-icon>
                          Persona
                        </v-chip>
                        <v-chip
                          v-else
                          :color="`green lighten-4`"
                          class="ml-0 mr-1 black--text"
                          label
                          small
                        >
                          <v-icon dark> mdi-domain </v-icon>
                          Empresa
                        </v-chip>
                        {{ slotProps.item.datos }}
                      </template>
                      <template #selection="{ item }">
                        <v-chip
                          color="blue lighten-4"
                          v-if="item.tipo == 'contacto'"
                        >
                          <v-icon dark> mdi-account </v-icon>
                        </v-chip>
                        <v-chip color="green lighten-4" v-else>
                          <v-icon dark> mdi-domain </v-icon>
                        </v-chip>
                        {{ item.datos }}
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-select
                      v-model="elemento.banco"
                      :items="tipos"
                      item-text="nombre"
                      item-value="nombre"
                      label="Banco de la cuenta"
                      :rules="[
                        (v) => !!v || 'Seleccione el Banco de su cuenta',
                      ]"
                    >
                      <template v-slot:item="slotProps">
                        <img
                          :src="slotProps.item.imagen"
                          :class="['mr-2', 'em']"
                        />
                        {{ slotProps.item.nombre }}
                      </template>
                      <template #selection="{ item }">
                        <img :src="item.imagen" alt="" />
                        <v-chip color="blue lighten-4">{{
                          item.nombre
                        }}</v-chip>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="12" class="pb-2 pt-2">
                <p class="mb-1">Tipo de la cuenta</p>
                <v-btn-toggle
                  v-model="elemento.tipo"
                  color="primary"
                  mandatory
                  class="pt-0"
                >
                  <v-btn value="ahorros"> Ahorros </v-btn>
                  <v-btn value="corriente"> Corriente </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" md="12" class="pb-0 pt-0">
                <p class="mb-1">Moneda</p>
                <v-btn-toggle v-model="elemento.moneda" color="primary" mandatory>
                  <v-btn value="PEN">
                    <img
                      width="24"
                      height="24"
                      class="mr-2"
                      :src="require('@/assets/peru.svg')"
                    />
                    Soles
                  </v-btn>
                  <v-btn value="USD">
                    <img
                      width="24"
                      height="24"
                      class="mr-2"
                      :src="require('@/assets/eeuu.svg')"
                    />
                    Dolares
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" md="12" class="pb-0">
                <v-text-field
                  v-model="elemento.nrocuenta"
                  label="Número de cuenta"
                  :rules="[(v) => !!v || 'Ingrese el número de cuenta']"
                  prepend-inner-icon="mdi-bank"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" class="pb-0">
                <v-text-field
                  v-model="elemento.nrocuentainter"
                  label="Número de cuenta interbancaria"
                  prepend-inner-icon="mdi-bank-transfer"
                  
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="12" class="pb-0">
                <v-text-field
                  v-model="elemento.nombrecuenta"
                  label="Nombre de la cuenta"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
         <v-btn color="black" text @click="dialogoAbrir = false">
          <v-icon dark left> mdi-close </v-icon> Cerrar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="!formulario.validar"
          :loading="formulario.cargar"
          @click="modificar"
          >Modificar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "cuentabancaria.crear",
  data: () => ({
    dialogoAbrir: false,
    formulario: {
      validar: true,
      cargar: false,
    },
    elemPerfil: {
      perfil: "",
      idperfil: 0,
      datos: "",
      id: 0,
    },
    elemento: {
      banco: "",
      nrocuenta: "",
      nrocuentainter: "",
      nombrecuenta: "",
      idcliente: "",
      id: "",
      perfil: "",
      idempresa: "",
    },
    emailReglas: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+/.test(v) || "Email no es valido",
    ],
    tipos: [
      { nombre: "BCP", imagen: require("@/assets/bcp.png") },
      { nombre: "Interbank", imagen: require("@/assets/interbank.png") },
      /*{ nombre: "Scotiabank", imagen: require("@/assets/scotiabank.png") },
      { nombre: "BBVA", imagen: require("@/assets/bbva.png") },
      { nombre: "Pichincha", imagen: require("@/assets/pichincha.png") },
      { nombre: "Banbif", imagen: require("@/assets/banbif.png") },
      { nombre: "Mibanco", imagen: require("@/assets/mibanco.png") },
      { nombre: "Ripley", imagen: require("@/assets/ripley.png") },*/
    ],
    listaContactoEmpresas: [],
  }),
  created() {},
  computed: {
    ...mapState("cuentabancaria", ["all", "cuentabancariaeditar", "cuentaid"]),
    ...mapState("cliente", ["clientesele"]),
    ...mapState("empresa", ["contactoempresas"]),
  },
  methods: {
    ...mapActions("cuentabancaria", ["editar", "listarCuentasPorCliente"]),
    ...mapActions("empresa", ["listarContactoEmpresas"]),
    async mostrarDialogo() {
      await this.listarContactoEmpresas(this.cuentaid);
      this.listaContactoEmpresas = this.contactoempresas;

      let datos = {
        datos: this.cuentabancariaeditar.perfilnombre,
        id: this.cuentabancariaeditar.idperfil
      };

      this.elemPerfil = datos

      this.elemento = this.cuentabancariaeditar;
      //console.log(this.elem);
      this.dialogoAbrir = true;
      //console.log(this.$refs)
      //this.$refs.FocusOrderDelivery.focus();
      setTimeout(() => {
        this.$refs.FocusOrderDelivery.focus();
      }, 200);
      //await this.listarEmpresasPorCliente(this.clientesele.id)
    },
    async modificar() {
      this.formulario.cargar = true;
      await this.editar(this.elemento);
      await this.listarCuentasPorCliente(this.elemento.idcliente);
      this.formulario.cargar = false;
      this.dialogoAbrir = false;
    },
    cambiarPerfil(item) {
      if(item.tipo === 'contacto') {
         this.elemento.perfil = 'c';
      } else {
          this.elemento.perfil = 'e';
      }
      
      this.elemento.idperfil = item.id;
      this.elemento.razonsocial = item.datos
    },
  },
};
</script>