import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Perfil from '../views/Perfil.vue'
import Cuentas from '../views/Cuentas.vue'
import nueva_operacion from '../views/Nueva-operacion.vue'
import operaciones from '../views/Operaciones.vue'
import cambiar_clave from '../views/Cambiar-clave.vue'
import Lista_cuentas from '../views/Lista-cuentas.vue'
import store from '../store/index'
import nueva_operacion_efectivo from '../views/Nueva-operacion-efectivo.vue'
import operaciones_efectivo from '../views/Operaciones-en-efectivo.vue'

//import {mapActions} from 'vuex'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/perfil',
    name: 'Perfil',
    component: Perfil
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/cuentas',
    name: 'Cuentas',
    component: Cuentas
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/lista-cuentas',
    name: 'Lista_cuentas',
    component: Lista_cuentas
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/nueva-operacion',
    name: 'nueva-operacion',
    component: nueva_operacion
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/operaciones',
    name: 'operaciones',
    component: operaciones
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/cambiar-clave',
    name: 'cambiar-clave',
    component: cambiar_clave
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/nueva-operacion-efectivo',
    name: 'nueva-operacion-efectivo',
    component: nueva_operacion_efectivo
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
  {
    path: '/operaciones-en-efectivo',
    name: 'operaciones-en-efectivo',
    component: operaciones_efectivo
    //component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  //store.commit("setGlobalError", "You need to log in before you can perform this action.")
  //console.log(store.state.pivot)
/*var newItem = {
    'token': "holacomoestas",
};

  localStorage.setItem('cliente', JSON.stringify(newItem));*/

  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path)
  let logueado = localStorage.getItem('cliente')
  
  if(logueado)
  {
    let clientelogueado = JSON.parse(logueado)
    if(clientelogueado.token != null){
      await store.dispatch('checkLogin', clientelogueado.token )
    } else {
      //localStorage.removeItem('cliente')
    }
    
  }
  
  logueado = localStorage.getItem('cliente')
  //store.dispatch(llamarhola)
  //console.log(">>: " + !logueado + " >>: " + authRequired)
  if (!logueado && authRequired) {
    //console.log('aqui 1: ' + to.fullPath)
      next({
        path: '/login'
      })
  }else {
    //console.log('aqui 2: ' + to.fullPath)
    next()
  }
})

export default router
