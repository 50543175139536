import { direccion } from '../_extra/configuracion'

const state = {
    oresultado: {},
    operaciones: [],
    operacionesefectivo: [],
}

const actions = {
    async listarOperaciones({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/operaciones.php`, opciones)
            const contenido = await response.json()
            commit('obtnerOperaciones', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarDiezUltimasOperaciones({commit}) {
        const opciones = {
            method: 'GET'
        }
        try {
            let response = await fetch(`${direccion().url}/operaciones.php?accion=li002`, opciones)
            const contenido = await response.json()
            commit('obtenerDiezOperaciones', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarTodoClienteOperaciones({commit}, id) {
        const opciones = {
            method: 'GET'
        }
        try {
            
            let response = await fetch(`${direccion().url}/operaciones.php?accion=li004&idcliente=${id}`, opciones)
            const contenido = await response.json()
            commit('obtenerTodoClienteOperaciones', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async listarTodoClienteOperacionesEfectivo({commit}, id) {
        const opciones = {
            method: 'GET'
        }
        try {
            
            let response = await fetch(`${direccion().url}/operaciones.php?accion=li005&idcliente=${id}`, opciones)
            const contenido = await response.json()
            commit('obtenerTodoClienteOperacionesEfectivo', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async registrar({commit}, elementoRegistrar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoRegistrar)
        }
        try {
            //console.log(JSON.stringify(elementoRegistrar))
            let respuesta = await fetch(`${direccion().url}/operaciones.php?enviar=2021`, opciones)
            const contenido = await respuesta.json()
            commit('registrarOperacion', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async registrarefectivo({commit}, elementoRegistrar) {
        const opciones = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elementoRegistrar)
        }
        try {
            console.log(JSON.stringify(elementoRegistrar))
            let respuesta = await fetch(`${direccion().url}/operaciones.php?enviarefectivo=2021`, opciones)
            const contenido = await respuesta.json()
            commit('registrarOperacionEfectivo', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async editarEstadoCodigo({commit}, elemento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            //console.log(JSON.stringify(elemento))
            let respuesta = await fetch(`${direccion().url}/operaciones.php?accion=ed003&id=${elemento.id}`, opciones)
            const contenido = await respuesta.json()
            commit('editarEstadoOperacion', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async anularOperacionDinero({commit}, elemento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            //console.log(JSON.stringify(elemento))
            let respuesta = await fetch(`${direccion().url}/operaciones.php?accion=ed004&id=${elemento.id}`, opciones)
            const contenido = await respuesta.json()
            commit('anularOperacionCambio', contenido)
        } catch (error) {
            console.error(error)
        }
    },
    async expirarOperacionDinero({commit}, elemento) {
        const opciones = {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(elemento)
        }
        try {
            console.log(JSON.stringify(elemento))
            let respuesta = await fetch(`${direccion().url}/operaciones.php?accion=ed005&id=${elemento.id}`, opciones)
            const contenido = await respuesta.json()
            commit('expirarOperacionCambio', contenido)
        } catch (error) {
            console.error(error)
        }
    }
}

const mutations = {
    obtnerOperaciones(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operaciones = contenido
    },
    obtenerDiezOperaciones(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operaciones = contenido
    },
    obtenerTodoClienteOperaciones(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operaciones = contenido
    },
    obtenerTodoClienteOperacionesEfectivo(state, contenido) {
        state.oresultado = {resultado : contenido}
        state.operacionesefectivo = contenido
    },
    registrarOperacion(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
    registrarOperacionEfectivo(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
    editarEstadoOperacion(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
    anularOperacionCambio(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
    expirarOperacionCambio(state, contenido) {
        state.oresultado = {resultado : contenido}
    },
}

export const operacion = {
    namespaced: true,
    state,
    actions,
    mutations
}