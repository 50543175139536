<template>
  <div class="home" :class="altodinamico">
    <v-row class="mb-15">
      <v-col cols="12" md="8" sm="8" lg="6" xl="4" class="offset-md-1 pe-0">
        <v-stepper v-model="cotizacion" alt-labels>
          <v-stepper-header>
            <v-stepper-step
              step="1"
              v-bind:complete="cotizacion > 1"
              class="text-center"
            >
              Cotizar
              <small class="mt-1">Monto a cambiar</small>
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="2"
              v-bind:complete="cotizacion > 2"
              class="text-center"
            >
              Registrar
              <small class="mt-1">Cuentas</small>
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="3"
              v-bind:complete="cotizacion > 3"
              class="text-center"
            >
              Transferir
              <small class="mt-1">Dinero</small>
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              step="4"
              v-bind:complete="cotizacion > 4"
              class="text-center"
            >
              Recibir
              <small class="mt-1">El cambio</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card>
                <v-card-text class="px-1">
                  <v-row class="d-flex align-center">
                    <v-col cols="12" class="d-flex justify-center">
                      <vue-countdown
                        v-if="counting"
                        :time="tiempoespera"
                        v-slot="{ minutes, seconds }"
                        @end="onCountdownEnd"
                      >
                      <v-chip class=" white--text pa-5" color="indigo darken-4">
                        <v-icon class="mr-1"> mdi-clock-fast </v-icon>
                        Tipo de cambio valido por：
                        <span class="body-1 ">{{ minutes }} min. y {{ seconds }} seg.</span>
                      </v-chip>
                      </vue-countdown>
                    </v-col>
                    <v-col cols="12">
                      <v-btn-toggle
                        v-model="elem.tipo"
                        mandatory
                        class="d-flex justify-center mb-4"
                        @change="cambiar"
                      >
                        <v-btn class="text-body-1">
                          Compra
                          <v-chip
                            class="ma-2 text-body-1"
                            color="indigo"
                            text-color="white"
                          >
                            {{ elem.compra }}
                          </v-chip>
                        </v-btn>
                        <v-btn class="text-body-1">
                          Venta
                          <v-chip class="ma-2 text-body-1" color="primary">
                            {{ elem.venta }}
                          </v-chip>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                    <v-col cols="12" md="9">
                      <v-form
                        ref="formCotizacion"
                        v-model="formulario.validar"
                        class="montos"
                      >
                        <v-text-field
                          label="Tu envias"
                          filled
                          dense
                          v-model="elem.montoenvioformato"
                          @input="envias"
                          :rules="[
                            (v) =>
                              !!v || 'El monto a enviar no puede estar vacio',
                          ]"
                          required
                        >
                          <template v-slot:prepend-inner>
                            <img
                              width="24"
                              height="24"
                              :src="elem.imgenviarban"
                            />
                            <img
                              width="24"
                              height="24"
                              :src="elem.imgenviarcod"
                            />
                          </template>
                        </v-text-field>
                        <v-text-field
                          label="Tu recibes"
                          filled
                          dense
                          v-model="elem.montoreciboformato"
                          @input="recibiras"
                          :rules="[
                            (v) =>
                              !!v || 'El monto a recibir no puede estar vacio',
                          ]"
                          required
                        >
                          <template v-slot:prepend-inner>
                            <img
                              width="24"
                              height="24"
                              :src="elem.imgrecibirban"
                            />
                            <img
                              width="24"
                              height="24"
                              :src="elem.imgrecibircod"
                            />
                          </template>
                        </v-text-field>
                        <v-btn
                          color="primary"
                          :disabled="!formulario.validar"
                          :loading="formulario.cargar"
                          @click="validarsesion"
                        >
                          Siguiente
                          <v-icon right dark> mdi-arrow-right-bold </v-icon>
                        </v-btn>
                      </v-form>
                    </v-col>
                    <v-col cols="12" md="3">
                      <div class="d-flex justify-center mb-4">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="success"
                              fab
                              x-large
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="cambiar"
                            >
                              <v-icon>mdi-autorenew</v-icon>
                            </v-btn>
                          </template>
                          <span>Cambiar</span>
                        </v-tooltip>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-2 px-1 pe-4">
              <v-row>
                <v-col cols="12" class="d-flex justify-center">
                      <vue-countdown
                        v-if="counting"
                        :time="tiempoespera"
                        v-slot="{ minutes, seconds }"
                        @end="onCountdownEnd"
                      >
                      <v-chip class=" white--text pa-5" color="indigo darken-4">
                        <v-icon class="mr-1"> mdi-clock-fast </v-icon>
                        Tipo de cambio valido por：
                        <span class="body-1 ">{{ minutes }} min. y {{ seconds }} seg.</span>
                      </v-chip>
                      </vue-countdown>
                    </v-col>
              </v-row>
              <v-form ref="formCotizacion2" v-model="formulario2.validar">
                <v-card class="mx-auto pt-0">
                  <v-card-text>
                    <div>Cuenta desde nos envias</div>
                    <p class="text-h5 text--primary pb-1">
                      Tú envias
                      <img
                        width="20"
                        height="20"
                        class="ml-5"
                        :src="elem.imgenviarban"
                      /><img
                        width="20"
                        height="20"
                        class="ml-1"
                        :src="elem.imgenviarcod"
                      />
                      {{
                        parseFloat(elem.montoenvio).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })
                      }}
                    </p>
                    <div class="text-body-1 pb-4 text-start">
                      Cliente ejecutante:
                      <span class="font-weight-bold" color="indigo"
                        >{{ elemtemp.nombres }} {{ elemtemp.apellidos }}</span
                      >
                    </div>
                    <v-row>
                      <v-col cols="10" md="10">
                        <v-select
                          v-model="elem.cuentaenvio"
                          :items="cuentasoles"
                          item-text="banco"
                          item-value="id"
                          label="Cuenta desde donde envia"
                          dense
                          outlined
                          return-object
                          no-data-text="Aún no tiene cuentas registradas"
                          :rules="[
                            (v) =>
                              !!v || 'Seleccione la cuenta bancaria de envio',
                          ]"
                          @change="itemChanged"
                          required
                        >
                          <template v-slot:item="slotProps">
                            <img
                              :src="
                                require('@/assets/' +
                                  slotProps.item.banco.toLowerCase() +
                                  '.png')
                              "
                              :class="['mr-2', 'em']"
                            />
                            <img
                              :src="
                                require('@/assets/' +
                                  slotProps.item.moneda.toLowerCase() +
                                  '-flag.svg')
                              "
                              :class="['mr-2', 'em', 'cuentas-size']"
                            />
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ slotProps.item.banco }}
                              </v-list-item-title>
                              <v-list-item-title>
                                {{ slotProps.item.nrocuenta }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <template #selection="{ item }">
                            <img
                              :src="
                                require('@/assets/' +
                                  item.banco.toLowerCase() +
                                  '.png')
                              "
                              class="mr-2"
                            />
                            <img
                              :src="
                                require('@/assets/' +
                                  item.moneda.toLowerCase() +
                                  '-flag.svg')
                              "
                              :class="['mr-2', 'em', 'cuentas-size']"
                            />
                            {{ item.banco }}
                          </template>
                        </v-select>
                        <v-text-field
                          v-model="elem.nrocuentaenvio"
                          label="Número de cuenta"
                          filled
                          dense
                          readonly
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" md="2">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="success"
                              fab
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="nuevacuenta"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Añadir cuenta</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <div>Cuenta para recibir</div>
                    <p class="text-h5 text--primary pb-1">
                      Tú recibes
                      <img
                        width="20"
                        height="20"
                        class="ml-5"
                        :src="elem.imgrecibirban"
                      /><img
                        width="20"
                        height="20"
                        class="ml-1"
                        :src="elem.imgrecibircod"
                      />
                      {{
                        parseFloat(elem.montorecibo).toLocaleString("en-US", {
                          maximumFractionDigits: 2,
                        })
                      }}
                    </p>
                    <div class="text-body-1 pb-4 text-start">
                      Cliente beneficiario:
                      <span class="font-weight-bold" color="indigo">{{
                        elemPerfil.datos
                      }}</span>
                    </div>
                    <v-row>
                      <v-col cols="10" md="10">
                        <v-select
                          v-model="elem.cuentarecepcion"
                          :items="cuentadolares"
                          item-text="banco"
                          item-value="id"
                          label="Cuenta para recibir el dinero"
                          dense
                          outlined
                          return-object
                          no-data-text="Aún no tiene cuentas registradas"
                          :rules="[
                            (v) =>
                              !!v ||
                              'Seleccione la cuenta bancaria para recibir el dinero',
                          ]"
                          @change="itemChanged2"
                          required
                        >
                          <template v-slot:item="slotProps">
                            <img
                              :src="
                                require('@/assets/' +
                                  slotProps.item.banco.toLowerCase() +
                                  '.png')
                              "
                              :class="['mr-2', 'em']"
                            />
                            <img
                              :src="
                                require('@/assets/' +
                                  slotProps.item.moneda.toLowerCase() +
                                  '-flag.svg')
                              "
                              :class="['mr-2', 'em', 'cuentas-size']"
                            />
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ slotProps.item.banco }}
                              </v-list-item-title>
                              <v-list-item-title>
                                {{ slotProps.item.nrocuenta }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                          <template #selection="{ item }">
                            <img
                              :src="
                                require('@/assets/' +
                                  item.banco.toLowerCase() +
                                  '.png')
                              "
                              class="mr-2"
                            />
                            <img
                              :src="
                                require('@/assets/' +
                                  item.moneda.toLowerCase() +
                                  '-flag.svg')
                              "
                              :class="['mr-2', 'em', 'cuentas-size']"
                            />
                            {{ item.banco }}
                          </template>
                        </v-select>
                        <v-text-field
                          v-model="elem.nrocuentarecepcion"
                          label="Número de cuenta"
                          filled
                          dense
                          readonly
                          required
                          solo
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2" md="2">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              color="success"
                              fab
                              small
                              dark
                              v-bind="attrs"
                              v-on="on"
                              @click="nuevacuenta2"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Añadir cuenta</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-row v-if="mayorcuantia >= 5000">
                      <v-col cols="10" class="pt-0">
                        <div
                          class="text-subtitle-1 pb-2 black--text font-weight-medium"
                        >
                          Procedencia del dinero
                        </div>
                        <v-select
                          v-model="elemProcendencia.elemento"
                          :items="procedencias"
                          label="Indique la procedencia del dinero"
                          :rules="[(v) => !!v || 'Seleccione una procedencia']"
                          outlined
                          dense
                          @change="validarProcedencia"
                        >
                        </v-select>
                      </v-col>
                      <v-col
                        cols="10"
                        class="pt-0"
                        v-if="elemProcendencia.mostrarprocedencia"
                      >
                        <div
                          class="text-subtitle-1 pb-2 black--text font-weight-medium"
                        >
                          Describe la procedencia
                        </div>
                        <v-textarea
                          v-model="elemProcendencia.procedenciaotros"
                          :rules="[(v) => !!v || 'Ingrese la procedencia']"
                          outlined
                          clearable
                          rows="2"
                          clear-icon="mdi-close-circle"
                          required
                          dense
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      text
                      @click.native="cotizacion = 1"
                      @click="ajustarpantalla"
                    >
                      <v-icon left dark> mdi-keyboard-return </v-icon>
                      Regresar
                    </v-btn>
                    <v-btn
                      color="primary"
                      :disabled="!formulario2.validar"
                      @click.native="cotizacion = 3"
                      @click="listarCuenta"
                    >
                      Registrar
                      <v-icon right dark> mdi-arrow-right-bold </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-form>
            </v-stepper-content>
            <v-stepper-content step="3" class="pt-0 px-1 pe-4">
              <div
                class="text-h6 primary--text pb-2 pt-3 pb-sm-4 pt-sm-5 text-center"
              >
                Transferir el dinero
              </div>
              <v-row class="pa-0 pb-0">
                <v-col cols="12">
                  <v-card color="#F8EB2E" class="py-0">
                    <v-card-text class="py-2 px-0 px-sm-2">
                      <v-chip class="ma-2" color="white">
                        <v-icon class="mr-1"> mdi-clock-fast </v-icon>
                        <span class="subheading"
                          >Cuenta con:
                          <span
                            class="font-weight-bold primary--text text-body-1"
                            >{{ regresivaVista }}</span
                          >
                          para realizar la transferencia.</span
                        >
                      </v-chip>
                      <v-icon class="mr-1">
                        mdi-clock-time-four-outline
                      </v-icon>
                      <span class="subheading mr-2 black--text"
                        >Tiempo limite:
                        <span
                          class="font-weight-bold black--text text-body-1"
                          >{{ elemResul.tiempofin }}</span
                        ></span
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="pa-2 pb-5 pa-sm-4 pb-sm-8 registration-info">
                <v-icon class="mr-1"> mdi-creation </v-icon>
                <span class="subheading mr-2"
                  >Nro de registro : {{ elem.idregistro }}</span
                >
                <span class="mr-1">|</span>
                <v-icon class="mr-1"> mdi-calendar-check </v-icon>
                <span class="subheading">fecha: {{ elem.fechaimpresion }}</span>
              </v-row>
              <v-row>
                <v-col cols="12" class="light-blue lighten-5">
                  <div class="text-body-2 pb-4 text-start px-5 black--text">
                    Realiza la tranferencia de dinero a nuestra cuenta bancaria.
                  </div>
                  <v-card max-width="400" class="mx-auto">
                    <v-card-text>
                      <div class="font-weight-bold ml-8 mb-2">Datos</div>

                      <v-timeline align-top dense>
                        <v-timeline-item
                          v-for="message in datocuenta"
                          :key="message.id"
                          :color="message.color"
                          :icon="message.icono"
                          fill-dot
                          small
                          class="pb-2"
                        >
                          <div>
                            <div class="font-weight-normal">
                              <span class="text-button">{{
                                message.descripcion
                              }}</span>
                            </div>
                            <img
                              width="14"
                              height="14"
                              :src="message.img"
                              v-if="message.img"
                              class="me-2"
                            />
                            <span
                              class="black--text font-weight-bold text-body-1"
                              >{{ message.valor }}</span
                            >
                            <v-btn
                              v-if="message.boton == 'si'"
                              color="primary"
                              fab
                              x-small
                              outlined
                              class="ml-2"
                              @click="copiarValor(message.valor)"
                            >
                              <v-icon>mdi-content-copy</v-icon>
                            </v-btn>
                          </div>
                        </v-timeline-item>
                      </v-timeline>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="mt-3 px-5 px-sm-3">
                <v-col cols="12">
                  <div>
                    ¿Ya transferiste? envianos una foto de la transfencia a:
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex flex-md-row flex-column justify-center align-center"
                >
                  <v-btn
                    color="green"
                    dark
                    large
                    href="https://api.whatsapp.com/send?phone=51997327111"
                    target="_blank"
                  >
                    <v-icon left> mdi-whatsapp </v-icon> Enviar a WhatsApp
                  </v-btn>
                  <span class="mx-2">o</span>
                  <v-btn
                    color="blue"
                    dark
                    large
                    href="mailto:info@cambio24peru.com"
                    target="_blank"
                  >
                    <v-icon left> mdi-whatsapp </v-icon> Enviar al correo
                  </v-btn>
                </v-col>
                <v-col cols="12">
                  <div>
                    <b>Si deseas puedes copiar el codigo de operación aqui</b> :
                  </div>
                </v-col>

                <v-col cols="12" class="d-flex pt-0 pb-0">
                  <v-text-field
                    v-model="verifica"
                    outlined
                    label="Ingrese Codigo de Operación"
                    :rules="[
                      (val) => (val || '').length > 0 || 'Ingrese el codigo',
                    ]"
                    required
                    class="Operacion text-codigo"
                    max-width="400"
                  >
                    <template v-slot:prepend>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="pink darken-3">
                            mdi-code-equal
                          </v-icon>
                        </template>
                        Codigo
                      </v-tooltip>
                    </template>
                    <template v-slot:append-outer>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            color="pink darken-3 mt-0 "
                            :disabled="!validar"
                            :loading="loading"
                            @click="enviarcodigo"
                            dark
                          >
                            Enviar
                          </v-btn>
                        </template>
                        Enviar
                      </v-tooltip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="mb-3">
                  <v-btn
                    text
                    @click.native="cotizacion = 2"
                    @click="reiniciartiempoespera"
                    class="mt-2 mb-2 mr-5"
                  >
                    <v-icon left dark> mdi-keyboard-return </v-icon>
                    Regresar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click.native="cotizacion = 4"
                    @click="mandarTransferencia"
                    class="mt-2 mb-2"
                  >
                    Confirmar transferencia
                    <v-icon right dark> mdi-arrow-right-bold </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
            <v-stepper-content step="4" class="pt-0">
              <div class="text-center text-h6 green--text pb-4 pt-5">
                Transferencia a la cuenta del cliente.
              </div>
              <v-card class="mx-auto pt-0" color="grey lighten-4">
                <v-card-text class="black--text">
                  <div class="text-body-2 pb-4">
                    !Listo! gracias por realizar la transferencia. Si es
                    correcto, nosotros realizaremos la transferencia de dinero
                    en aproximadamente 15 min. Y lo recibirá en la siguiente
                    cuenta:
                  </div>
                  <v-list-item
                    v-for="item in datocuentatransferir"
                    :key="item.id"
                    class="black--text d-flex flex-sm-row flex-column"
                  >
                    <v-list-item-title class="d-flex flex-row">
                      <div>
                        <v-icon class="cyan--text text--darken-3">
                          {{ item.icono }}
                        </v-icon>
                        {{ item.descripcion }}
                      </div>
                    </v-list-item-title>
                    <v-list-item-title
                      class="text-start black--text font-weight-bold"
                    >
                      <img
                        width="14"
                        height="14"
                        :src="item.img"
                        v-if="item.img"
                      />
                      {{ item.valor }}
                      <v-btn
                        v-if="item.boton == 'si'"
                        color="primary"
                        fab
                        x-small
                        outlined
                        class="ml-2"
                        @click="copiarValor(item.valor)"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-card-text>
              </v-card>
              <v-row class="mt-2">
                <v-col cols="12" class="mb-3 mt-3">
                  <v-btn
                    color="green"
                    class="white--text float-right"
                    href="/#/operaciones"
                    :right="true"
                  >
                    Ver operaciones efectivo
                    <v-icon right dark> mdi-playlist-play </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="4"
        lg="5"
        xl="7"
        align="center"
        justify="center"
      >
        <v-card class="mt-2" max-width="344">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-img :src="imagenayuda" indeterminate>
                  <div :class="altoimagen"></div>
                </v-img>
              </v-col>

              <v-col cols="6">
                <div class="d-inline pa-2 orange accent-4 white--text">
                  Compra
                </div>
                <div class="d-inline pa-2 pl-4 pr-4 black white--text">
                  {{ elem.compra }}
                </div>
              </v-col>
              <v-col cols="6">
                <div class="d-inline pa-2 orange accent-4 white--text">
                  Venta
                </div>
                <div class="d-inline pa-2 pl-4 pr-4 black white--text">
                  {{ elem.venta }}
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <cuentabancaria-crear
        ref="dialogoCuentabancariaCrear"
        v-on:childToParent="onChildClick"
      />
    </v-row>
    <v-row>
      <cuentabancaria-crear
        ref="dialogoCuentabancariaCrear2"
        v-on:childToParent="onChildClick2"
      />
    </v-row>
    <v-row>
      <cuentabancaria-crear-operacion
        ref="dialogoCuentabancariaCrearOperacion"
        v-on:childToParent="onChildClickPerfil"
      />
    </v-row>
    <v-row>
      <cuentabancaria-crear-operacion
        ref="dialogoCuentabancariaCrearOperacion2"
        v-on:childToParent="onChildClickPerfil2"
      />
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog2" max-width="450">
        <v-card>
          <v-avatar :color="colormensaje">
            <v-icon dark>
              {{ iconomensaje }}
            </v-icon>
          </v-avatar>
          <v-card-title class="text-h5 justify-center headerClass">
            {{ titulomensaje }}
          </v-card-title>
          <v-card-text class="pt-4 text-body-1 font-weight-medium text-justify"
            >{{ textomensaje }}
          </v-card-text>
          <v-card-actions>
            <v-btn color="black darken-1" text @click="dialog2 = false">
              Cerrar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              class="white--text"
              @click="continuarTransferi()"
            >
              Confirmar Transferencia
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogPerfil" persistent max-width="450">
        <v-card>
          <v-card-title class="pb-4">
            <h3 class="text-no-wrap text-h6">
              ¿Que perfil desea usar para esta operación?
            </h3>
          </v-card-title>
          <v-card-text class="mt-2">
            <v-row>
              <v-col
                v-for="(item, i) in listaContactoEmpresas"
                :key="i"
                cols="12"
                sm="6"
                md="6"
              >
                <v-card @click="seleccionarPerfil(item)">
                  <div class="d-flex flex-no-wrap justify-space-between py-3">
                    <div>
                      <div class="text-overline ps-4 pt-3 pb-0 primary--text">
                        <v-icon
                          color="primary"
                          v-if="item['tipo'] == 'empresa'"
                        >
                          mdi-domain
                        </v-icon>
                        <v-icon color="primary" v-else> mdi-account </v-icon>
                        {{ item["tipo"] }}
                      </div>
                      <v-card-title class="text-body-1 py-2 pt-0 black--text">
                        {{ item["datos"] }}
                      </v-card-title>
                      <v-card-subtitle class="py-0 pt-2">
                        {{ item["tipodocumento"] }}: {{ item["nrodocumento"] }}
                      </v-card-subtitle>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogRespuesta" persistent max-width="450">
        <v-card>
          <v-avatar color="red">
            <v-icon dark> mdi-cancel </v-icon>
          </v-avatar>
          <v-card-title class="text-h6 justify-center headerClass">
            Se ha agotado su hora limite de envio de transferencia.
          </v-card-title>
          <v-card-text class="pt-4 text-display-2 font-weight-medium"
            >Lo sentimos ya no podemos mantener el tipo de cambio de esta
            operación, realice una nueva cotizacion.
          </v-card-text>
          <v-card-text class="pt-2 text-display-2 font-weight-medium"
            >Si usted ya hizo la transferencia antes del tiempo limite,
            escribanos al WhatsApp y confirmaremos su operación.
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="salirOperacion">
              Salir
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row class="pe-5">
      <v-col cols="12" class="text-right">
        <v-btn
          color="green accent-4"
          fixed
          dark
          bottom
          right
          fab
          href="https://api.whatsapp.com/send?phone=51997327111"
          target="_blank"
          class="mb-3"
        >
          <v-icon>mdi-whatsapp</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from "../router";
import { mapState, mapActions } from "vuex";
import CuentabancariaCrear from "../components/cuentabancaria.crear.vue";
import CuentabancariaCrearOperacion from "../components/cuentabancaria.crearoperacion.vue";
import moment from "moment";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import VueCountdown from "@chenfengyuan/vue-countdown";

const mask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});
const mask2 = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "Home",
  components: {
    CuentabancariaCrear,
    CuentabancariaCrearOperacion,
    VueCountdown,
  },
  data: () => ({
    mask,
    mask2,
    counting: true,
    dialogRespuesta: false,
    listaContactoEmpresas: [],
    dialogPerfil: false,
    mayorcuantia: 0,
    altoimagen: "",
    imagenayuda: "",
    verifica: "",
    textomensaje: "",
    iconomensaje: "",
    titulomensaje: "",
    colormensaje: "",
    dialog2: false,
    mostrarmensaje: false,
    isDisabled: false,
    loading: false,
    validar: true,
    alto: 500,
    formulario: {
      validar: true,
      cargar: false,
    },
    formulario2: {
      validar: true,
      cargar: false,
    },
    elemPerfil: {},
    elem: {
      test: "",
      compra: "",
      venta: "",
      enviar: "USD",
      recibir: "PEN",
      imgenviarban: require("@/assets/eeuu.svg"),
      imgenviarcod: require("@/assets/usd.svg"),
      imgrecibirban: require("@/assets/peru.svg"),
      imgrecibircod: require("@/assets/pen.svg"),
      cambio: false,
      tipo: 0,
      montoenvio: "",
      montorecibo: "",
      montoenvioformato: "",
      montoreciboformato: "",
      cuentaenvio: "",
      nrocuentaenvio: "",
      idcuentaenvio: "",
      cuentarecepcion: "",
      nrocuentarecepcion: "",
      idcuentarecepcion: "",
      idregistro: "cargando...",
      fecharegistro: "",
      fechaimpresion: "",
      bancoenvio: "",
      bancorecepcion: "",
      nombre: "",
      nombrecompleto: "",
      idcliente: "",
      dni: "",
      email: "",
      tipocambio: "",
      operaciontipo: "",
      procedencia: "",
      idempresa: "",
      perfil: "",
      ruc: "",
      razonsocial: "",
      tipocuentaempresa: "",
      tipocuentaenviar: "",
      tipocuentarecibir: "",
    },
    elemtemp: {
      nombres: "",
      apellidos: "",
      cuentastam: 0,
      moneda: "USD",
      montoenvio: 0,
      idcuentaenvio: "",
      idcuentarecepcion: "",
    },
    elemResul: {
      tiempofin: "nn",
      diferencia: "",
    },
    cotizacion: 1,
    isToggled: false,
    datocuenta: [],
    datocuentatransferir: [],
    cuentadolares: [],
    cuentasoles: [],
    ochominutos: 0,
    cuentaregresiva: moment(60 * 10 * 1000),
    cuentaregresivacotizacion: moment(60 * 5 * 1000),
    tiempocontar: null,
    procedencias: [
      "001: AHORROS",
      "002: ALQUILER DE BIENES MUEBLES",
      "003: ALQUILER DE BIENES INMUEBLES",
      "004: DONACIÓN/SORTEO",
      "005: INGRESOS POR TRABAJO INDEPENDIENTE",
      "006: INGRESOS POR TRABAJO DEPENDIENTE",
      "007: INGRESOS POR REGALIA",
      "008: PRESTAMOS",
      "009: VENTA DE BIEN MUEBLE",
      "010: VENTA DE BIEN INMUEBLE",
      "099: OTROS",
    ],
    elemProcendencia: {
      elemento: "",
      procedenciaotros: "",
      mostrarprocedencia: false,
    },
    tiempoespera: 8 * 60 * 1000,
    tiempopivot: 1,
  }),
  mounted() {
    this.tiempocontar = setInterval(() => {
      this.cuentaregresiva = moment(
        this.cuentaregresiva.subtract(1, "seconds")
      );
      this.cuentaregresivacotizacion = moment(
        this.cuentaregresivacotizacion.subtract(1, "seconds")
      );

      if (this.cuentaregresiva.format("mm:ss") == "00:00") {
        if (this.elemResul.tiempofin != "nn") {
          clearInterval(this.tiempocontar);
          this.dialogRespuesta = true;
          let datos = {
            nro: this.elem.idregistro,
            email: this.elem.email,
            nombres: this.elem.nombrecompleto,
            id: this.elem.idregistro.substring(3),
          };

          this.expirarOperacionDinero(datos);
        }
      }
    }, 1000);
    /* setTimeout(() => {
      console.log(this.cuentabancariacliente[2])
        this.elem.cuentaenvio = this.cuentabancariacliente[2];
    }, 1000);*/
  },
  computed: {
    ...mapState("tipocambio", ["tipocambioultimo"]),
    ...mapState("cliente", ["clientes", "clientesele"]),
    ...mapState("cuentabancaria", [
      "all",
      "cuentabancariacliente",
      "cuentabancariacliente2",
      "cuentabancariaPerfil",
    ]),
    ...mapState("cuentausuario", ["cuentausuarios"]),
    ...mapState("operacion", ["oresultado"]),
    ...mapState(["pivot"]),
    ...mapState("empresa", ["contactoempresas"]),
    /*cuentaenvio : {
      get () {
        return this.$store.state.cuentaenvio
      },
      set (value) {
        this.$store.commit('cuentabancaria/asignarCuentaLista', value)
      }
    },*/

    regresivaVista: function () {
      return this.cuentaregresiva.format("mm:ss");
    },
    regresivaVistaCotizacion: function () {
      return this.cuentaregresivacotizacion.format("mm:ss");
    },
    diplayClass() {
      let classString = "";
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          classString = "subtitle-2 text-no-wrap justify-center";
          break;
        case "sm":
          classString = "subtitle-1 justify-center";
          break;
        case "md":
          classString = "h5 justify-center";
          break;
        case "lg":
          classString = "h6 justify-center";
          break;
        case "xl":
          classString = "h6 justify-center";
          break;
      }
      return classString;
    },
    altodinamico() {
      let classString = "alto-" + this.$vuetify.breakpoint.name;

      return classString;
    },
  },
  async created() {
    await this.listarContactoEmpresas(this.clientes.id);
    this.listaContactoEmpresas = this.contactoempresas;

    //this.$store.dispatch('cuentabancaria/asignarCuentaenlista', 2)
    await this.listarUltimoTipocambio("11-11-21");
    this.elem.compra = this.tipocambioultimo[0].compra;
    this.elem.venta = this.tipocambioultimo[0].venta;
    await this.listarClientePorId(this.clientes.id);
    this.elemtemp.nombres = this.clientesele.nombres;
    this.elemtemp.apellidos =
      this.clientesele.apaterno + " " + this.clientesele.amaterno;
    this.elem.idcliente = this.clientesele.id;
    this.elem.dni = this.clientesele.nrodocumento;
    this.elem.email = this.clientesele.email;

    /*
    await this.listarCuentasPorClienteSoles(this.clientes.id);
    await this.listarCuentasPorClienteDolares(this.clientes.id);

    this.cuentasoles = this.cuentabancariacliente2;
    this.cuentadolares = this.cuentabancariacliente;*/

    //let count = 60 * 0.5;
    //this.startTimer(count);

    this.ajustarpantalla();

    this.altoimagen = "alto-imagen-a";
    this.imagenayuda = require("@/assets/cambio-paso-1.gif");
  },
  methods: {
    ...mapActions("tipocambio", ["listarUltimoTipocambio"]),
    ...mapActions("cliente", ["listarClientePorId"]),
    ...mapActions("cuentabancaria", [
      "agregar",
      "listarCuentasPorClienteSoles",
      "listarCuentasPorClienteDolares",
      "asignarCuentaenlista",
      "asignarCuentabancariaAgregarId",
      "soloAsignarPerfil",
    ]),
    ...mapActions("cuentausuario", ["listarCuentaBancoMoneda"]),
    ...mapActions("operacion", [
      "registrar",
      "editarEstadoCodigo",
      "expirarOperacionDinero",
    ]),
    ...mapActions(["checkLogin"]),
    ...mapActions("empresa", ["listarContactoEmpresas"]),
    async validarsesion() {
      let clientelogueado = JSON.parse(localStorage.getItem("cliente"));
      if (!clientelogueado) {
        router.push("/login");
        window.location.reload();
      } else {
        await this.checkLogin(clientelogueado.token);
        if (this.pivot !== "0") {
          router.push("/login");
          window.location.reload();
        }
      }
      if (!this.elem.cambio) {
        this.mayorcuantia = this.elem.montoenvio;
      } else {
        this.mayorcuantia = this.elem.montorecibo;
      }
      //console.log(this.mayorcuantia);
      this.imagenayuda = require("@/assets/cambio-paso-2.gif");
      // modificacion
      if (this.cotizacion === 1) {
        this.ajustarpantalla();
        this.dialogPerfil = true;
      } else {
        this.dialogPerfil = false;
      }
      //this.cotizacion = 2;
    },
    onCountdownEnd: async function () {
      this.tiempoespera = 8 * (this.tiempopivot + 60) * 1000
      if(this.tiempopivot == 1){
        this.tiempopivot = 0
      } else {
        this.tiempopivot = 1
      }
      await this.listarUltimoTipocambio("11-11-21");
      this.elem.compra = this.tipocambioultimo[0].compra;
      this.elem.venta = this.tipocambioultimo[0].venta;
      this.envias()
    },
    ajustarpantalla() {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 200);
    },
    cambiar() {
      if (this.elem.cambio) {
        this.elem.imgenviarban = require("@/assets/eeuu.svg");
        this.elem.imgenviarcod = require("@/assets/usd.svg");
        this.elem.imgrecibirban = require("@/assets/peru.svg");
        this.elem.imgrecibircod = require("@/assets/pen.svg");
        this.elem.cambio = false;
        this.elem.tipo = 0;
        this.elem.enviar = "USD";
        this.elem.recibir = "PEN";
        this.cuentasoles = this.cuentabancariacliente2;
        this.cuentadolares = this.cuentabancariacliente;
        this.elem.nrocuentaenvio = "";
        this.elem.nrocuentarecepcion = "";
      } else {
        this.elem.imgenviarban = require("@/assets/peru.svg");
        this.elem.imgenviarcod = require("@/assets/pen.svg");
        this.elem.imgrecibirban = require("@/assets/eeuu.svg");
        this.elem.imgrecibircod = require("@/assets/usd.svg");
        this.elem.cambio = true;
        this.elem.tipo = 1;
        this.elem.enviar = "PEN";
        this.elem.recibir = "USD";
        this.cuentasoles = this.cuentabancariacliente;
        this.cuentadolares = this.cuentabancariacliente2;
        this.elem.nrocuentaenvio = "";
        this.elem.nrocuentarecepcion = "";
      }
      this.envias();
    },
    envias() {
      let menvio = "";
      let cotizacion = 0;
      if (this.elem.montoenvioformato != "") {
        menvio = parseFloat(this.elem.montoenvioformato.replace(/,/g, ""));
        if (this.elem.cambio) {
          cotizacion = menvio / this.elem.venta;
        } else {
          cotizacion = menvio * this.elem.compra;
        }

        this.elem.montoenvioformato = menvio.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
        this.elem.montoreciboformato = cotizacion.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
        this.elem.montoenvio = menvio;
        this.elem.montorecibo = cotizacion;
      }
    },
    recibiras() {
      let mrecibes = "";
      let cotizacion = 0;
      if (this.elem.montoreciboformato != "") {
        mrecibes = parseFloat(this.elem.montoreciboformato.replace(/,/g, ""));
        if (this.elem.cambio) {
          cotizacion = mrecibes * this.elem.venta;
        } else {
          cotizacion = mrecibes / this.elem.compra;
        }
        this.elem.montoreciboformato = mrecibes.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
        this.elem.montoenvioformato = cotizacion.toLocaleString("en-US", {
          maximumFractionDigits: 2,
        });
        this.elem.montorecibo = mrecibes;
        this.elem.montoenvio = cotizacion;
      }
    },
    async nuevacuenta() {
      //console.log(this.elemPerfil);
      this.soloAsignarPerfil(this.elemPerfil);
      this.$refs.dialogoCuentabancariaCrearOperacion.mostrarDialogo();
    },
    async nuevacuenta2() {
      this.soloAsignarPerfil(this.elemPerfil);
      this.$refs.dialogoCuentabancariaCrearOperacion2.mostrarDialogo();
    },
    onChildClick(value) {
      let indice = value - 1;
      if (this.elem.cambio) {
        this.cuentasoles = this.cuentabancariacliente;
        //console.log(this.cuentadolares);
        this.elem.cuentaenvio = this.cuentabancariacliente[indice];
        this.itemChanged(this.cuentabancariacliente[indice]);
      } else {
        this.cuentasoles = this.cuentabancariacliente2;
        //console.log(this.cuentasoles);
        this.elem.cuentaenvio = this.cuentabancariacliente2[indice];
        this.itemChanged(this.cuentabancariacliente2[indice]);
      }
    },
    onChildClick2(value) {
      let indice = value - 1;
      if (this.elem.cambio) {
        this.cuentadolares = this.cuentabancariacliente2;
        this.elem.cuentarecepcion = this.cuentabancariacliente2[indice];
        this.itemChanged2(this.cuentabancariacliente2[indice]);
      } else {
        this.cuentadolares = this.cuentabancariacliente;
        this.elem.cuentarecepcion = this.cuentabancariacliente[indice];
        this.itemChanged2(this.cuentabancariacliente[indice]);
      }
    },
    onChildClickPerfil(value) {
      //console.log(value);
      let indice = value - 1;
      if (this.elem.cambio) {
        this.cuentasoles = this.cuentabancariacliente;
        //console.log(this.cuentadolares);
        this.elem.cuentaenvio = this.cuentabancariacliente[indice];
        this.itemChanged(this.cuentabancariacliente[indice]);
      } else {
        this.cuentasoles = this.cuentabancariacliente2;
        //console.log(this.cuentasoles);
        this.elem.cuentaenvio = this.cuentabancariacliente2[indice];
        this.itemChanged(this.cuentabancariacliente2[indice]);
      }
    },
    onChildClickPerfil2(value) {
      //console.log(value);
      let indice = value - 1;
      if (this.elem.cambio) {
        this.cuentadolares = this.cuentabancariacliente2;
        this.elem.cuentarecepcion = this.cuentabancariacliente2[indice];
        this.itemChanged2(this.cuentabancariacliente2[indice]);
      } else {
        this.cuentadolares = this.cuentabancariacliente;
        this.elem.cuentarecepcion = this.cuentabancariacliente[indice];
        this.itemChanged2(this.cuentabancariacliente[indice]);
      }
    },
    itemChanged(item) {
      this.elem.idcuentaenvio = item.id;
      this.elem.bancoenvio = item.banco;
      this.elem.nrocuentaenvio = item.nrocuenta;
      this.elem.tipocuentaenviar = item.tipo;
    },
    itemChanged2(item) {
      this.elem.bancorecepcion = item.banco;
      this.elem.nrocuentarecepcion = item.nrocuenta;
      this.elem.idcuentarecepcion = item.id;
      this.elem.tipocuentarecibir = item.tipo;
    },
    async listarCuenta() {
      await this.validarsesion();
      this.ajustarpantalla();
      this.altoimagen = "alto-imagen-b";
      this.imagenayuda = require("@/assets/tranferencia-bcp.gif");

      let cuentainfo = {
        moneda: this.elem.recibir,
        banco: this.elem.bancoenvio,
      };

      //console.log(cuentainfo)

      if (this.elem.cambio) {
        this.elem.tipocambio = this.elem.venta;
        this.elem.operaciontipo = "venta";
      } else {
        this.elem.tipocambio = this.elem.compra;
        this.elem.operaciontipo = "compra";
      }
      this.elem.nombre = this.elemtemp.nombres;
      this.elem.nombrecompleto =
        this.elemtemp.nombres + " " + this.elemtemp.apellidos;
      this.elem.texto = "envio";
      await this.listarCuentaBancoMoneda(cuentainfo);

      this.elem.bancoempresa = this.cuentausuarios[0].banco; //bancon a enviar
      this.elem.nrocuentaempresa = this.cuentausuarios[0].nrocuenta; //cuenta a enviar
      this.elem.idcuentaempresa = this.cuentausuarios[0].id; //idcuenta empresa
      this.elem.tipocuentaempresa = this.cuentausuarios[0].tipo; //idcuenta empresa

      this.datocuenta = [
        {
          icono: "mdi-cash-check",
          descripcion: "Monto a tranferir",
          valor: this.elem.montoenvio, // numero acambiar
          img: this.elem.imgenviarcod,
          boton: "si",
          color: "deep-purple lighten-1",
        },
        {
          icono: "mdi-bank-outline",
          descripcion: "Banco",
          valor: this.cuentausuarios[0].banco,
          boton: "no",
          color: "blue lighten-1",
        },
        {
          icono: "mdi-file-move",
          descripcion: "Número de cuenta",
          img: this.elem.imgenviarban,
          valor: this.cuentausuarios[0].nrocuenta,
          boton: "si",
          color: "green",
        },
        {
          icono: "mdi-cards-playing-outline",
          descripcion: "Tipo de cuenta",
          valor: this.cuentausuarios[0].tipo,
          boton: "si",
          color: "blue lighten-1",
        },
        {
          icono: "mdi-card-account-details-star-outline",
          descripcion: "Titular",
          valor: "ASESORES Y CONSULTORES FINANCIEROS S & Z",
          boton: "no",
          color: "blue lighten-1",
        },
      ];

      this.datocuentatransferir = [
        {
          icono: "mdi-cash-check",
          descripcion: "Monto a recibir",
          valor: this.decimalformato(this.elem.montorecibo, 2),
          img: this.elem.imgrecibircod,
          boton: "no",
        },
        {
          icono: "mdi-bank-outline",
          descripcion: "Banco",
          valor: this.elem.bancorecepcion,
          boton: "no",
        },
        {
          icono: "mdi-file-move",
          descripcion: "Número de cuenta",
          valor: this.elem.nrocuentarecepcion,
          boton: "no",
        },
        {
          icono: "mdi-cards-playing-outline",
          descripcion: "Tipo de cuenta",
          valor: this.elem.tipocuentarecibir,
          boton: "no",
        },
        {
          icono: "mdi-card-account-details-star-outline",
          descripcion: "Titular",
          valor: this.elem.nombrecompleto,
          boton: "no",
        },
        {
          icono: "mdi-card-account-details-star-outline",
          descripcion: "Documento",
          valor: this.elem.dni,
          boton: "no",
        },
      ];

      if (
        this.elemtemp.moneda == this.elem.enviar &&
        this.elemtemp.montoenvio == this.elem.montoenvio &&
        this.elemtemp.idcuentaenvio == this.elem.idcuentaenvio &&
        this.elemtemp.idcuentarecepcion == this.elem.idcuentarecepcion
      ) {
        console.log("registrado");
      } else {
        this.elemtemp.moneda = this.elem.enviar;
        this.elemtemp.montoenvio = this.elem.montoenvio;
        this.elemtemp.idcuentaenvio = this.elem.idcuentaenvio;
        this.elemtemp.idcuentarecepcion = this.elem.idcuentarecepcion;
        var dt = new Date();
        this.elem.fecharegistro = `${dt
          .getFullYear()
          .toString()
          .padStart(4, "0")}-${dt.getDate().toString().padStart(2, "0")}-${(
          dt.getMonth() + 1
        )
          .toString()
          .padStart(2, "0")} ${dt.getHours().toString().padStart(2, "0")}:${dt
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${dt.getSeconds().toString().padStart(2, "0")}`;

        this.elem.fechaimpresion = `${(dt.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${dt.getDate().toString().padStart(2, "0")}/${dt
          .getFullYear()
          .toString()
          .padStart(4, "0")} ${dt.getHours().toString().padStart(2, "0")}:${dt
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${dt.getSeconds().toString().padStart(2, "0")}`;
        //console.log(this.elem)

        this.elem.verificacion = "";
        this.elem.idempresa = this.elemPerfil.id;
        this.elem.perfil = this.elemPerfil.tipo;
        if (this.elemProcendencia.mostrarprocedencia == true) {
          this.elem.procedencia =
            this.elemProcendencia.elemento +
            " - " +
            this.elemProcendencia.procedenciaotros;
        } else {
          this.elem.procedencia = this.elemProcendencia.elemento;
        }

        this.elem.montorecibo = this.decimalformato(this.elem.montorecibo, 2);

        await this.registrar(this.elem); //cambioreg
        //console.log(this.oresultado.resultado)
        this.cuentaregresiva = moment(
          this.oresultado.resultado[0].minutos * 1000
        );
        this.elemResul.tiempofin = this.oresultado.resultado[0].horalimite;
        this.elem.idregistro = this.oresultado.resultado[0].coder;

        
      }
      this.counting = false
    },
    decimalformato(value, decimalPlaces) {
      let res = Number(
        Math.round(parseFloat(value + "e" + decimalPlaces)) +
          "e-" +
          decimalPlaces
      ).toFixed(decimalPlaces);
      return res;
    },
    async copiarValor(value) {
      navigator.clipboard.writeText(value);
    },
    async enviarcodigo() {
      await this.validarsesion();
      //this.validar = false;
      this.loading = true;
      let elementos = {
        nombre: this.elem.nombrecompleto,
        estado: "validando",
        email: this.elem.email,
        nro: this.elem.idregistro,
        verificacion: this.verifica,
        id: this.elem.idregistro.substring(3),
      };

      if (this.verifica == undefined) {
        this.verifica = "0";
      }
      await this.editarEstadoCodigo(elementos);
      if (this.oresultado.resultado.coderror == "0") {
        this.iconomensaje = "mdi-check-circle-outline";
        this.titulomensaje = "Verificaremos el código enviado";
        this.textomensaje =
          "Gracias por enviar tu código de operación. Si el código enviado es correcto, en unos minutos estaremos enviado tu dinero.";
        this.colormensaje = "green";
        clearInterval(this.tiempocontar);
        this.tiempofin = 'nn'
        
      } else {
        this.iconomensaje = "mdi-chat-alert-outline";
        this.titulomensaje = "Problemas de envio";
        this.textomensaje =
          "Por favor ingrese nuevamente el codigo de operación, y envielo.";
        this.colormensaje = "yellow";
      }
      this.dialog2 = true;
      this.loading = false;
      //this.validar = true;
    },
    async seleccionarPerfil(item) {
      //console.log(item)
      this.elemPerfil = item;
      this.dialogPerfil = false;
      this.cotizacion = 2;

      if (item.tipodocumento == "RUC") {
        this.elem.ruc = item.nrodocumento;
        this.elem.razonsocial = item.datos;
      }

      await this.listarCuentasPorClienteSoles(item);
      await this.listarCuentasPorClienteDolares(item);

      if (!this.elem.cambio) {
        this.cuentasoles = this.cuentabancariacliente2;
        this.cuentadolares = this.cuentabancariacliente;
        this.elem.cambio = false;
        this.elem.tipo = 1;
        this.elem.enviar = "PEN";
        this.elem.recibir = "USD";
      } else {
        this.cuentasoles = this.cuentabancariacliente;
        this.cuentadolares = this.cuentabancariacliente2;
        this.elem.cambio = true;
        this.elem.tipo = 0;
        this.elem.enviar = "USD";
        this.elem.recibir = "PEN";
      }
    },
    reiniciartiempoespera(){
      this.counting = true
      this.tiempoespera = 8 * (60) * 1000
    },
    salirOperacion() {
      window.location.reload();
    },
    mandarTransferencia() {
      clearInterval(this.tiempocontar);
      this.tiempofin = 'nn'
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 200);
    },
    validarProcedencia(item) {
      //console.log(item);
      if (item == "099: Otros") {
        this.elemProcendencia.mostrarprocedencia = true;
      } else {
        this.elemProcendencia.mostrarprocedencia = false;
      }
    },
    continuarTransferi() {
      clearInterval(this.tiempocontar);
      this.tiempofin = 'nn'
      this.dialog2 = false;
      this.cotizacion = 4;
      this.ajustarpantalla();
    },
  },
};
</script>
<style>
.v-application--is-ltr .v-stepper__label {
  text-align: center !important;
}
@media only screen and (max-width: 959px) {
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
    align-items: center;
    font-size: 14px;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label small {
    display: none;
  }
  .registration-info .v-icon.v-icon {
    font-size: 12px;
  }
  .registration-info .subheading {
    font-size: 14px;
  }
  .v-application .text-h6 {
    font-size: 17px !important;
  }
  .lista-transferir.v-list-item::after {
    min-height: 7px;
  }
  .lista-transferir .titulo-transf {
    font-size: 14px;
  }
  .v-list-item::after {
    min-height: 20px;
  }
}
.text-codigo {
  max-width: 500px;
}
.text-codigo .v-input__append-outer {
  margin-top: 10px;
}
.lista-transferir .ttitulo-transf {
  font-size: 12px;
}
.lista-transferir .v-icon.v-icon {
  font-size: 15px;
}
.cuentas-size {
  width: 100%;
  max-width: 22px;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
.montos .v-text-field input {
  font-size: 1.5em;
}

.correotexto .v-input__control fieldset,
.correotexto .v-text-field__slot label,
.correotexto .v-text-field__slot label i {
  border-color: #1976d2 !important;
  color: #1976d2 !important;
}
.whastapp .v-input__control fieldset,
.whastapp .v-text-field__slot label,
.whastapp .v-text-field__slot label i {
  border-color: #0bc934 !important;
  color: #0bc934 !important;
}
.codigo .v-input__control fieldset,
.codigo .v-text-field__slot label,
.codigo .v-text-field__slot label i {
  border-color: #c90b8a !important;
  color: #c90b8a !important;
}
.correotexto .v-input__append-inner,
.whastapp .v-input__append-inner {
  margin-top: 12px !important;
}
.altoc-xs {
  min-height: calc(100vh - 40px);
}
.altoc-sm {
  min-height: calc(100vh - 40px);
}
.altoc-md {
  min-height: calc(100vh - 40px);
}
.altoc-lg {
  min-height: calc(100vh - 40px);
}
.altoc-xl {
  min-height: calc(100vh - 40px);
}
.alto-imagen-a {
  height: 220px;
}
.alto-imagen-b {
  height: 150px;
}
</style>