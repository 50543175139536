<template>
  <v-app>
    <Barmenu v-show="estado.logueado"/>
    <v-main :class="(!estado.logueado)?'sinActive':'dotActive'">
        <router-view />
         <Footer  />
    </v-main>
   
  </v-app>
  
</template>

<script>

import { mapState } from 'vuex'

import Barmenu from './components/Barmenu';
import Footer from './components/Footer.vue';

export default {
  name: 'App',

  components: {
    Barmenu,
    Footer
  },

  data: () => ({
    logueado:false
    //
  }),
  created () {
      console.log(this.estado.logueado)
  },
  computed: {
    ...mapState('cliente', ['estado'])
  }
};
</script>
<style>
.sinActive {
  padding: 0px 0px 0px 0px !important;
}
.dotActive {
  padding: 0px 0px 0px 0px;
}
</style>
