<template>
  <v-dialog v-model="dialogoAbrir" max-width="500px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline text-h6">Modificar perfil</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="formEmpresa" v-model="formulario.validar">
          <v-container>
            <v-col cols="12" class="pb-5 pt-0">
              <div class="text-start black--text font-weight-bold">
                Datos de la Persona
              </div>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-text-field
                v-model="elem.email"
                :rules="emailReglas"
                label="Email"
                outlined
                required
                dense
                ref="focusemail"
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="pb-0 pt-0">
              <v-text-field
                ref="phone"
                v-model="elem.celular"
                label="Celular"
                :rules="[(v) => !!v || 'Su numero de contacto es requerido']"
                outlined
                required
                dense
              ></v-text-field>
            </v-col>
            <v-row class="mx-0 px-0 d-flex py-2">
              <v-col cols="12" md="6" class="pb-0 pt-0">
                <v-select
                  v-model="elem.tipodocumento"
                  :items="tipos"
                  label="Tipo de documento"
                  :rules="[(v) => !!v || 'Seleccione el tipo de documento']"
                  dense
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" class="pb-0 pt-0" md="6">
                <v-text-field
                  v-model="elem.nrodocumento"
                  label="Número de documento"
                  :rules="[(v) => !!v || 'El número es requerido']"
                  outlined
                  required
                  dense
                  ref="focusdocumento"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-col cols="12" class="pb-0 pt-0">
              <v-text-field
                v-model="elem.nombres"
                label="Nombres"
                :rules="[(v) => !!v || 'El nombre es requerido']"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-text-field
                v-model="elem.apaterno"
                label="Apellido Paterno"
                :rules="[(v) => !!v || 'El apellido parterno es requerido']"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-text-field
                v-model="elem.amaterno"
                label="Apellido Materno"
                :rules="[(v) => !!v || 'El apellido materno es requerido']"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-text-field
                v-model="elem.direccion"
                label="Dirección"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-select
                v-model="elemUbigeo.departamento"
                :items="listadepartamentos"
                item-text="nombre_ubigeo"
                item-value="id_ubigeo"
                label="Departamento"
                return-object
                @change="cambiarDepartamento"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-select
                v-model="elemUbigeo.provincia"
                :items="listaprovincias"
                item-text="nombre_ubigeo"
                item-value="id_ubigeo"
                label="Provincia"
                return-object
                @change="cambiarProvicia"
                outlined
                dense
              >
                <template v-slot:no-data>
                  <div class="px-4 pb-3 mt-4">
                    Seleccione el departamento...
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-select
                v-model="elemUbigeo.distrito"
                :items="listadistritos"
                item-text="nombre_ubigeo"
                item-value="id_ubigeo"
                label="Distrito"
                return-object
                @change="cambiarDistrito"
                outlined
                dense
              >
                <template v-slot:no-data>
                  <div class="px-4 pb-3 mt-4">
                    Seleccione el departamento y la provincia...
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-select
                v-model="elem.ocupacion"
                :items="ocupaciones"
                label="Ocupación"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0">
              <v-select
                v-model="elem.pep"
                :items="peps"
                label="¿Es un PEP?"
                outlined
                dense
                @change="validarPep"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" dark v-bind="attrs" v-on="on">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <div max-width="500">
                      Personas Expuestas Políticamente son las personas
                      naturales, <br />
                      nacionales o extranjeras, que cumplen o que en los últimos
                      cinco (5) años han cumplido <br />
                      funciones públicas destacadas o funciones prominentes en
                      una organización internacional.
                    </div>
                    <span> </span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0" v-if="mostrarPep">
              <v-text-field
                v-model="elem.institucion"
                label="Institucion"
                :rules="[(v) => !!v || 'La institucion es requerida']"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0 pt-0" v-if="mostrarPep">
              <v-text-field
                v-model="elem.cargo"
                label="Cargo"
                :rules="[(v) => !!v || 'El cargo es requerido']"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="!formulario.validar"
          :loading="formulario.cargar"
          @click="modificar"
          >Modificar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { ubigeo } from "../_extra/ubigeo";
//import PersonaEditar from "../views/Perfil.vue";

export default {
  name: "empresa.editar",
  data: () => ({
    mostrarPep: false,
    dialogoAbrir: false,
    formulario: {
      validar: true,
      cargar: false,
    },
    elem: {
      nombres: "",
      apaterno: "",
      amaterno: "",
      direccion: "",
      email: "",
      celular: "",
      tipodocumento: "DNI",
      nrodocumento: "",
      nacionalidad: "Peru",
      pep: "",
      ocupacion: "",
      habilitado: "SI",
      estadocivil: "",
      institucion: "",
      cargo: "",
      fechanacimiento: "1900-01-01",
      departamento: "",
      provincia: "",
      distrito: "",
    },
    elemUbigeo: {
      departamento: "",
      provincia: "",
      distrito: "",
    },
    tipos: [
      "DNI",
      "RUC",
      "CARNET EXTRANJERIA",
      "CÉDULA CIUDADANA",
      "PASAPORTE",
      "CÉDULA DIPLOMATICA",
    ],
    ocupaciones: [
      "Abogado (a)",
      " Administrador(a)",
      "Agricultor(a)",
      "Ama de casa",
      "Arquitecto(a)",
      "Artista",
      "Cambista",
      "Comerciante",
      "Consultor(a)",
      "Contador(a)",
      "Diseñador(a)",
      "Docente",
      "Economista",
      "Empresario(a)",
      "Enfermero(a)",
      "Estudiante",
      "Funcionario Público",
      "Importador o exportador",
      "Ingeniera(o)",
      "Ingeniera(o)",
      "Jubilado",
      "Médico",
      "Militar",
      "Obstetriz",
      "Odontólogo(a)",
      "Periodista",
      "Policía",
      "Psicólogo(a)",
      "Publicista",
      "Seguridad",
      "Trabajador dependiente",
      "Trabajador Independiente",
      "Trabajador(a) Social",
      "Vendedor(a)",
      "Otros",
    ],
    peps: ["SI", "NO"],
    listadepartamentos: [],
    listaprovincias: [],
    listadistritos: [],
    emailReglas: [
      (v) => !!v || "Email es requerido",
      (v) => /.+@.+/.test(v) || "Email no es valido",
    ],
  }),
  created() {},
  computed: {
    ...mapState("cliente", ["all", "clienteEditar"]),
  },
  methods: {
    ...mapActions("cliente", ["editar", "resultado"]),
    async mostrarDialogo() {
      this.cargarDepartamento();
      //console.log(this.clienteEditar[0])
      this.elem = this.clienteEditar[0];
      this.dialogoAbrir = true;
      let temp = {};

      if (this.clienteEditar[0].departamento != "") {
        this.listadepartamentos.forEach((i) => {
          if (i.nombre_ubigeo === this.clienteEditar[0].departamento) {
            temp = i;
          }
        });
        this.elemUbigeo.departamento = temp;
        this.listaprovincias = ubigeo().resultado.provincias[temp.id_ubigeo];

        temp = {};
        this.listaprovincias.forEach((i) => {
          if (i.nombre_ubigeo === this.clienteEditar[0].provincia) {
            temp = i;
          }
        });

        this.elemUbigeo.provincia = temp;

        if (temp.id_ubigeo != null) {
          this.listadistritos = ubigeo().resultado.distritos[temp.id_ubigeo];
          temp = {};
          this.listadistritos.forEach((i) => {
            if (i.nombre_ubigeo === this.clienteEditar[0].distrito) {
              temp = i;
            }
          });
          this.elemUbigeo.distrito = temp;
        }

        this.validarPep(this.clienteEditar[0].pep);
        this.elem.institucion = this.clienteEditar[0].institucion;
        this.elem.cargo = this.clienteEditar[0].cargo;
      } else {
        console.log("--");
      }
      //console.log(this.$refs)
      //this.$refs.FocusOrderDelivery.focus();
      setTimeout(() => {
        this.$refs.focusdocumento.focus();
      }, 200);
      //await this.listarEmpresasPorCliente(this.clientesele.id)
    },
    async modificar() {
      this.formulario.cargar = true;
      if (!this.elemUbigeo.departamento) {
        this.elem.departamento = "";
      } else {
        this.elem.departamento = this.elemUbigeo.departamento.nombre_ubigeo;
      }
      if (!this.elemUbigeo.departamento) {
        this.elem.provincia = "";
      } else {
        this.elem.provincia = this.elemUbigeo.provincia.nombre_ubigeo;
      }
      if (!this.elemUbigeo.distrito) {
        this.elem.distrito = "";
      } else {
        this.elem.distrito = this.elemUbigeo.distrito.nombre_ubigeo;
      }

      if (this.elem.institucion == null) {
        this.elem.institucion = "";
      }
      if (this.elem.cargo == null) {
        this.elem.cargo = "";
      }
      await this.editar(this.elem);
      //await this.listarEmpresasPorCliente(this.elem.id);
      //console.log(PersonaEditar.methods.mensajeDialog())
      //console.log(this.resultado);

      this.$emit("force-render");

      this.formulario.cargar = false;
      this.dialogoAbrir = false;
    },
    cargarDepartamento() {
      this.listadepartamentos = ubigeo().resultado.departamentos;
      this.listaprovincias = [];
      this.listadistritos = [];
    },
    cambiarDepartamento(item) {
      this.listaprovincias = ubigeo().resultado.provincias[item.id_ubigeo];
      this.listadistritos = [];
      this.elem.departamento = item.nombre_ubigeo;
    },
    cambiarProvicia(item) {
      this.listadistritos = ubigeo().resultado.distritos[item.id_ubigeo];
      this.elem.provincia = item.nombre_ubigeo;
    },
    cambiarDistrito(item) {
      this.elem.distrito = item.nombre_ubigeo;
    },
    validarPep(item) {
      if (item == "SI") {
        this.mostrarPep = true;
      } else {
        this.mostrarPep = false;
      }
    },
  },
};
</script>