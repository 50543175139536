import Vue from 'vue'
import Vuex from 'vuex'
import { direccion } from '../_extra/configuracion'

import { alerta } from './Alerta.modulo'
import { cliente } from './Cliente.modulo'
import { empresa } from './Empresa.modulo'
import { cuentabancaria } from './Cuentabancaria.modulo'
import { tipocambio } from './Tipocambio.modulo'
import { cuentausuario } from './Cuentausuario.modulo'
import { operacion } from './Operacion.modulo'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pivot: ''
  },
  mutations: {
    setGlobalError (state, error) {
      state.pivot = error
    },
    checkLoginToken(state, resultado){
      state.pivot = resultado
    }
  },
  actions: {  
    async checkLogin({commit},token) {
      const pedidoOpciones = {
          method: 'POST',
          headers: { 'Content-type':'application/json' },
          body: JSON.stringify({ token })
      }
      try {
          let respuesta = await fetch(`${direccion().url}/clientes.php?accion=ag004`, pedidoOpciones)
          const contenido = await respuesta.json()
          let codigo = '0'
          if(contenido.coderror === 'Expired token')
          {
            localStorage.removeItem('cliente')
            codigo = '1'
          } 
          else if(contenido.coderror === 'Syntax error, malformed JSON')
          {
            localStorage.removeItem('cliente')
            codigo = '2'
          }
          else if(contenido.coderror == 'Signature verification failed')
          {
            
            localStorage.removeItem('cliente')
            codigo = '3'
          } 
          else if(contenido.coderror == 'Wrong number of segments')
          {
            
            localStorage.removeItem('cliente')
            codigo = '4'
          } 
          cliente.state.exptime = contenido.coderror
          //console.log(cliente.state.exptime)
          commit('checkLoginToken', codigo)
          
      } catch (error) {
          console.error(error)
      }
    }
  },
  modules: {
    alerta,
    cliente,
    empresa,
    cuentabancaria,
    tipocambio,
    cuentausuario,
    operacion
  }
})
