<template>
    <v-footer
        color="#F5F5F5"
    padless
    class="mt-5"
  >
  <v-row class="main-container "
        >
        <v-col
            cols="12"
            md="4"
        >
            <div class="text-h6 ma-5">
                <img src="../assets/logo-cambio24.png" alt="" width="180" class="pl-5">
            </div>
            <div class="text-body-1 ma-5 pb-2 mt-2">
                Realice sus operaciones de cambios con total seguridad y confianza.
            </div>
            <div class="text-body-1 ma-5 pb-0 mb-0">
                ASESORES Y CONSULTORES FINANCIEROS S & Z
            </div>
            <div class="text-body-1 ma-5 pb-0 mb-0">
                RUC:<br> <span class="fw-bold">20611981334</span>
            </div>
            <div class="text-body-1 ma-5 pb-2 mt-2">
                
            </div>
        </v-col>
          <v-col
            cols="12"
            md="4"
        >
            <div class="text-body-1 ma-5 font-weight-medium">
                Paginas
            </div >
            <v-row
            no-gutters
                v-for="link in links"
                :key="link.text"
                color="white"
                text
                rounded
                class="my-2 mb-0"
                
            >
                    <a
                        class="mb-0 ml-6 text-body-2"
                        :href="link.url" target="_blank"
                        color="primary"
                    >
                        {{ link.text }}
                    </a>
            </v-row>
            <div class="text-body-1 ma-5 font-weight-medium">
                Horario de atención
            </div >
            <div class="text-body-1 ma-5">
                Lunes a Viernes:<br>
                9:00 am a 6:00 pm <br><br> 
                Sábados:<br>
                9:00 am a 1:00pm 
            </div>
            <div>
                
            </div>
        </v-col>
        <v-col
            cols="12"
            md="4"
        >
            <div class="text-body-1 ma-5 font-weight-medium">
                Contacto
            </div >
            <v-list-item
                v-for="folder in folders"
                :key="folder.title"
            >
                <v-list-item-avatar>
                <v-icon
                    class="teal darken-3"
                    dark
                >
                    {{ folder.icon }}
                </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title v-text="folder.title" class="text-caption"></v-list-item-title>

                <v-list-item-subtitle v-text="folder.subtitle" class="text-body-1 font-weight-bold black--text" color="#fff"></v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <div class="text-body-1 ma-5 font-weight-medium">
                Redes sociales
            </div >
            <v-btn
                v-for="icon in icons"
                :key="icon.id"
                class="mx-4 black--text"
                icon
                >
                <v-icon size="24px">
                    {{ icon.icon }}
                </v-icon>
            </v-btn>
            
        </v-col>
        <v-divider></v-divider>
        <v-col
            cols="12"
            md="12"
            class="pb-0"
        >
            <v-card
            flat
            tile
            class="grey lighten-3 black--text text-center"
            >
            <v-card-text class="black--text">
                {{ new Date().getFullYear() }} — <strong> Cambio24 © Todos los derechos reservados</strong>
            </v-card-text>
            </v-card>
        </v-col>
        </v-row>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      icons: [
        { id: 1, icon: 'mdi-facebook' },
        { id: 2, icon: 'mdi-instagram' },
      ],
      links: [
        { text: 'Nosotros', url: 'https://cambio24peru.com/sobre-nosotros/' },
        { text: 'Registro', url: 'https://registro.cambio24peru.com/' },
        { text: 'Iniciar un cambio', url: 'https:/app.cambio24peru.com/' },
        { text: 'Terminos y condiciones', url: 'https://cambio24peru.com/terminos-y-condiciones' },
        { text: 'Politicas de privacidad', url: 'https://cambio24peru.com/politicas-de-privacidad' },
      ],
      folders: [
        {
          subtitle: '+51 997 327 111',
          title: 'WhatsApp',
          icon: 'mdi-whatsapp',
        },
        {
          subtitle: 'operaciones@cambio24peru.com',
          title: 'E-mail',
          icon: 'mdi-email',
        },
      ],

    }),
  }
</script>
<style >
.main-container {
  position: relative !important;
  max-width: 1200px !important;
      margin: 0 auto;
}
</style>